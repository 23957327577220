<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/warning.svg"></div>
			<div class="title">{{ $t('subscription.cancel_title') }}</div>
		</div>
		<div class="description-modal" v-html="$t('subscription.cancel_contact_us')"></div>

		<div class="button-actions">
			<button class="btn-tbf blue center" @click="cancelAction">
				<div class="loader"></div>
				<div class="text">{{ $t('general.ok_thanks_long') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		props: {
            data: Object
        },
		data() {
			return {

			};
		},
		mounted() {
			this.sendOnlyEmail()
		},
		methods: {
			cancelAction(){
				this.$emit("close_modal");
			},
			submitAction(){
				var buttonName = `buttonSubmitDelete`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				axios.post(`${this.data.soft_link}cancel-subscription`, {cancel_at_period_end: true})
				.then(() => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							this.$auth.fetch().then( () => { this.$emit("close_modal") })
						}, 1000)
					}, 300)
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			sendOnlyEmail(){
				axios.post(`${this.data.soft_link}cancel-subscription-mail`)
				.then(() => {
					this.$auth.fetch();
                    this.$store.dispatch('applications/getApplications');
				})
			}
		}
	};
</script>