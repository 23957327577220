<template>
    <div class="modal-tbf modal-full-page">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{data.edited ? $t('department.edit') : $t('department.title')}}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="cancelSubmit"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('general.name')}}*</label>
                            </div>
                            <div class="input-box bg-white department" v-bind:class="{has_error: $v.name.$error}">
                                <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                                <input type="text" :placeholder="$t('general.name_placeholder')" class="input-text no-icon" v-model="name">
                            </div>
                        </div>

                        <div class="input-group w-100">
                            <div class="input-box bg-white">
                                <textarea rows="3" :placeholder="$t('general.description_placeholder')" v-model="description"></textarea>
                            </div>
                        </div>

                        <div class="input-group w-100">
                            <div class="label-header" >
                                <label class="label">{{ $t('departments.choose_color')}}</label>
                            </div>
                            <div class="input-box flex bg-white">
                                <div class="diez-character">#</div>
                                <input type="text" class="input-text no-icon" v-model="color" maxlength="6" :placeholder="$t('departments.choose_color_ph')">
                                <div class="form__input">
                                    <v-swatches
                                        v-model="color_code"
                                        popover-x="left"
                                        swatches="text-advanced"
                                    ></v-swatches>
                                </div>
                            </div>
                        </div>

                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('departments.responsible')}}</label>
                            </div>
                            <div class="input-box bg-white">
                                <div class="icon-left"><icon-people /></div>
                                <div class="icon-right" v-if="selectedUser != ''" @click.stop="selectedUser = ''"><icon-close class="icon-clear" /></div>
                                <multiselect 
                                v-model="selectedUser"
                                v-bind:class="{populate: selectedUser != ''}"
                                :options="optionsUsers"
                                :allow-empty="true"
                                :show-labels="false"
                                :maxHeight="200"
                                track-by="id" 
                                label="name"
                                >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{$t('departments.choose-responsible')}}
                                        </span>
                                    </template>
                                    <template slot="noResult">{{$t('departments.no-results')}}</template>
                                    <template slot="noOptions">{{$t('departments.empty-list')}}</template>
                                </multiselect>
                            </div>
                        </div>

                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('departments.parent-department')}}</label>
                            </div>
                            <div class="input-box bg-white">
                                <div class="icon-left"><icon-department /></div>
                                <div class="icon-right" v-if="parentDepartment != ''" @click.stop="parentDepartment = ''"><icon-close class="icon-clear" /></div>
                                <multiselect 
                                v-model="parentDepartment"
                                v-bind:class="{populate: parentDepartment != ''}"
                                :options="departments"
                                :allow-empty="true"
                                :show-labels="false"
                                track-by="id" 
                                label="name"
                                :maxHeight="200"
                                openDirection="top"
                                >
                                    <template slot="placeholder" slot-scope="props">
                                        <span class="text">
                                            {{$t('departments.choose-department')}}
                                        </span>
                                    </template>
                                    <template slot="noResult">{{$t('departments.no-results')}}</template>
                                    <template slot="noOptions">{{$t('departments.empty-list')}}</template>>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form-submit department">
                        <button class="btn-tbf grey center" @click="cancelSubmit">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.cancel')}}</div>
                        </button>
                        <div class="error-msg" v-show="error_message!=''">{{error_message}}</div>
                        <button v-if="!data.edited" id='buttonCreateDepartment' class="btn-tbf blue center" @click="saveAction('create')">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.add')}}</div>
                        </button>
                        <button v-else id='buttonCreateDepartment' class="btn-tbf blue center" @click="saveAction('update')">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.update')}}</div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
                <div class="overlay-submodal"></div>
                <modal-confirm-close id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
            </div>
    	</div>
        <department-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import IconPeople from '../Icons/User'
import ModalConfirmClose from '../Modals/ConfirmClose'
import { required } from 'vuelidate/lib/validators'
import IconDepartment from '../Icons/Department.vue'
import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue'
import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

	export default {
		data() {
			return {
                loaded: false,
                name: '',
                description: '',
                parentDepartment: '',
				departments: [],
				optionsUsers: [],
			  	selectedUser: '',
                usersSelected: [],
                subModalShow: false,
                show_confirm_close: false,
                error_message: '',
				color: '',
                color_code: ''
			};
		},
        components: {
            IconClose,
            IconPeople,
            ModalConfirmClose,
            IconDepartment,
            DepartmentLoaderModal,
            VSwatches
        },
        watch: {
			color_code (newValue, oldValue) {
				this.color = newValue.substring(1);
			},
			color (newValue, oldValue) {
				this.color_code = newValue ? `#${newValue}` : ''
			}
		},
        props: {
            data: Object
        },
        validations: {
			name: {required},
		},
		async mounted() {
            await this.getUsers()

            if(this.data.hasOwnProperty('parent_id') && this.data.parent_id){
                this.parentDepartment = this.departments.find(el => el.id == this.data.parent_id)
            }
            
            if(this.data.hasOwnProperty('edited')){
                await this.getDepartmentData();
            }

            this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
            async getDepartmentData(){
                axios.get(`/departments/${this.data.slug}/edit`)
                .then(({data})=> {
                    this.department = data.data
                    this.name = this.department.name
                    this.color_code = this.department.color_code ? this.department.color_code : '';
				    this.color = this.department.color_code ? this.department.color_code.substring(1) : '';
                    this.description = this.department.description
                    this.selectedUser = this.department.responsible ? this.optionsUsers.find(el => el.id == this.department.responsible.id) : ''
                    this.usersSelected = this.department.users

                    this.departments.find(el => el.id == this.data.id).$isDisabled = true
                })
            },
            async getUsers(){
                await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true, departments: true}})
                .then(({data}) => {
                    this.optionsUsers = data.data.users
                    this.departments = data.data.departments
                }).catch(error => {
                    if(error.response) {
                        if(error.response.status == 500) {
                            alert(this.$t('error.500'))
                        }
                    }
                })
                .finally(() => {
                    this.loaded = true
                    setTimeout(() => {
                        $('.opacity-page').addClass('show')
                    }, 0)
                })
			},
			cancelSubmit(){
                if(this.checkInputs()){
                    this.subModalShow = true
                    this.show_confirm_close = true
                    setTimeout(() => {
                        document.getElementById('submodalContainer').classList.add('active')
                        setTimeout(() => {
                            document.getElementById('submodal_confirm_close').classList.add('active')
                        }, 200);
                    }, 0);
                }else{
                    this.$emit("close_modal");
                }
			},
            saveAction(type){
				var buttonName = `buttonCreateDepartment`
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true

				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					var objData = {
						name: this.name,
						description: this.description,
                        responsible_id: this.selectedUser ? this.selectedUser.id : '',
						department_id: this.parentDepartment ? this.parentDepartment.id : '',
                        users: this.usersSelected,
                        color_code: this.color_code
					}
					if(type == 'create'){
						this.createDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/departments/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('btn-submit.create')
                            this.$emit("close_modal");
                            if(this.data.from == 'chart'){
                                this.$root.$emit('refreshCompanyChart');
                            }else{
							    this.$root.$emit('addDropdownUserCreate', data.data, 'department');
							    this.$root.$emit('addDropdownVacantJobCreate', data.data, 'department');
                            }
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
            updateDepartment(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/departments/${this.data.slug}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
                            this.$emit("close_modal");
                            if(this.data.from == 'chart'){
                                this.$root.$emit('refreshCompanyChart');
                            }else{
							    this.$root.$emit('addDropdownUserCreate', data.data, 'department');
							    this.$root.$emit('addDropdownVacantJobCreate', data.data, 'department');
                            }
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
            checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
    #modal_department {
        max-width: 550px;
        z-index: 10
    }
    div.input-box.bg-white.department > input.no-icon{
        padding: 0 45px 0 15px;
    }
    .form-submit.department > button{
        width: 50%;
    }
</style>