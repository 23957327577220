<template>
	<div class="modal_info modal_responsibilities_unique">
		<div class="header-submodal with-btn">
			<div class="title">{{ `${$t('user-details.responsibilities')} ${data.userName}` }}</div>
			<div class="button-close">
				<div class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></div>
			</div>
		</div>

		<template v-if="loaded">
			<div class="list-entries" v-if="entries.length">
				<div v-for="entry in entries" class="item">
					<div class="name" @click="viewResponsibility(entry)">{{ entry.name }}</div>
					<div class="type">{{ entry.role_name }}</div>

					<div class="interval">
                        <v-popover offset="2" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf description-popover" :delay="{show: 0, hide: 0}" :disabled="entry.frequency == 'daily'">
                            <div class="name-frequency">{{ entry.frequency ? $t(`responsibility.frequencies.${entry.frequency}`) : '' }}</div>
                            <div class="description" v-if="entry.frequency == 'weekly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return $t(`calendar.long_days.${a}`) }).join(", ") : ''}}</div>
                            <div class="description" v-if="entry.frequency == 'monthly'">{{ entry.measurement.length ? entry.measurement.join(", ") : '' }}</div>
                            <div class="description" v-if="entry.frequency == 'monthly_dynamically'">{{ entry.measurement.length ? entry.measurement.map((a) => { return $t(`calendar.number_days.${a}`) }).join(", ") : ''}}</div>

                            <div class="description capitalize" v-if="entry.frequency == 'yearly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '' }}</div>

                            <template slot="popover">
                                <div class="simple-text" v-if="entry.frequency == 'weekly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return $t(`calendar.long_days.${a}`) }).join(", ") : ''}}</div>
                                <div class="simple-text" v-if="entry.frequency == 'monthly'">{{ entry.measurement.length ? entry.measurement.join(", ") : '' }}</div>
                                <div class="simple-text" v-if="entry.frequency == 'monthly_dynamically'">
                                {{ entry.measurement_dynamically.map((a) => { return $t(`calendar.weeks.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${$t('general.and')} $1`) }}
                                <br/>
                                {{ entry.measurement.map((a) => { return $t(`calendar.number_days.${a}`) }).join(", ").replace(/, ([^,]*)$/, ` ${$t('general.and')} $1`) }}
                                </div>

                                <div class="simple-text capitalize" v-if="entry.frequency == 'yearly'">{{ entry.measurement.length ? entry.measurement.map((a) => { return moment(a, 'MM-DD').format('DD.MM') }).join(", ") : '' }}</div>
                            </template>
                        </v-popover>
					</div>
				</div>
			</div>
		</template>
		<div class="list-days-off" v-else>
			<div class="placeholder-loader" v-for="n in 3" style="height: 30px; width: 100%; background-color: #f1f2f2; margin-bottom: 15px;"></div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'

	export default {
		data() {
			return {
				loaded: false,
				entries: [],
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconClose,
        	LoaderShowResponsibility,
        },
		mounted() {
			this.getResponsibilities();
		},
		methods: {
            getResponsibilities() {
                axios.get(`/${this.data.userSlug}/user-responsibilities`)
				.then(({data}) => {
					this.entries = data.data
				})
				.finally(() => { setTimeout(() => { this.loaded = true }, 0) })
            },
			closeModal(){
				this.$emit("close_modal");
			},
			viewResponsibility(entry) {
				this.$root.$emit('change_modal', 'show_responsibility', {
					from: 'list',
					userId: '',
					userSlug: '',
					id: entry.id,
					date: ''
				});
			}
		}
	};
</script>