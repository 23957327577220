<template>
	<div class="sidebar-settings-tbf scroll-updates">
		<div class="header">
			<div class="title">{{ nameOfSidebarList() }}</div>
			<button class="add-item btn-tbf blue center only-icon" @click="$router.push({ name: 'announcements-create' })" v-if="$auth.user().rights.admins_department_responsibles && ['announcements', 'announcements-show'].includes($route.name)">
				<div class="icon"><icon-plus /></div>
				<!-- <div class="text">{{ $t('announcements.add') }}</div> -->
			</button>
			<button class="btn-tbf white only-icon close-btn" @click="$emit('close_modal')" v-if="$resize && $mq.below(820)">
				<icon-close />
			</button>
		</div>

		<template v-if="['announcements', 'announcements-show'].includes($route.name)">
			<div class="search-input">
				<div class="input-box">
					<icon-search />
					<input type="text" :placeholder="$t('general.search_by_name')" v-model="search_filter" v-debounce:500="resetIndexAnnouncements"/>
				</div>
			</div>
		</template>

		<div class="main-menu" v-if="loaded">
			<list-announcements ref="listAnnouncements" v-if="['announcements', 'announcements-show'].includes($route.name)" :announcements="softwareUpdates"/>
			<template v-else>
				<template v-if="softwareUpdates.length">
					<div class="item-menu" v-for="update in softwareUpdates" :key="update.id">
						<router-link :to="{name: typeUrlShow, params: { id: update.id } }">
							<div class="text" :class="{new: !update.seen && ['software-updates', 'software-updates-show'].includes($route.name)}">
								<div class="date">{{ update.created_at | moment('DD MMM YYYY') }}</div>
								<div class="name">{{ update.name }}</div>
							</div>
							<div class="arrow"><icon-arrow /></div>
						</router-link>
					</div>
				</template>
				<div class="message-empty" v-else>{{ $t('software_updates.empty_title') }}</div>
			</template>
		</div>
		<div class="main-menu" v-else>
			<div class="item-menu">
				<a v-for="n in 3">
					<div class="placeholder-loader text" style="height: 16px; width: 130px;"></div>
				</a>
			</div>
		</div>

		<infinite-loading v-if="['announcements', 'announcements-show'].includes($route.name)" :identifier="infiniteId" @infinite="infiniteAnnouncementsHandler" ref="infiniteLoading"><div slot="spinner"></div><div slot="no-more"></div><div slot="no-results"></div></infinite-loading>
	</div>
</template>

<script>
    import IconArrow from '../../Icons/Arrow.vue'
    import IconPlus from '../../Icons/Plus.vue'
	import ListAnnouncements from '../Announcements/ListAnnouncements.vue'
	import InfiniteLoading from 'vue-infinite-loading'
    import IconSearch from '../../Icons/Search.vue'
	import IconClose from "../../Icons/Close"

	export default {
		components: {
			IconArrow,
			IconPlus,
			ListAnnouncements,
			InfiniteLoading,
			IconSearch,
			IconClose
		},
		data() {
			return {
				loaded: false,
				softwareUpdates: [],
				typeUrlShow: '',
				infiniteId: 1,
				page: 0,
                search_filter: '',
				forceRefresh: false
			};
		},
		watch:{
			$route (to, from){
				var softwareUpdates = ['software-updates', 'software-updates-show'];
				var workInProgress = ['work-in-progress', 'work-in-progress-show'];
				var announcements = ['announcements', 'announcements-show'];
				let toGroup = '';

				if(softwareUpdates.includes(to.name)) { 
					toGroup = 'softwareUpdates';
				} else if(workInProgress.includes(to.name)) {
					toGroup = 'workInProgress';
				} else if(announcements.includes(to.name)) {
					toGroup = 'announcements';
				}

				switch(toGroup) {
					case 'softwareUpdates': 
						if(!softwareUpdates.includes(from.name)) { this.getSoftwareUpdates() }
					break;
					case 'workInProgress': 
						if(!workInProgress.includes(from.name)) { this.getSoftwareUpdates() }
					break;
					case 'announcements': 
						if(!announcements.includes(from.name)) { this.resetIndexAnnouncements() }
					break;
				}
			},
			search_filter: function(val) {
				var data = Object.assign({}, this.$route.query);
				data['search'] = this.search_filter;

				if(this.search_filter == ""){ delete data['search'] }

				this.$router.push({query : data });
			}
		},
		async mounted() {
			var dataQuery = Object.assign({}, this.$route.query);
            Object.keys(dataQuery).forEach(key => {
                if(key == 'search'){ this.search_filter = dataQuery[key] }
            });

			if(['announcements', 'announcements-show'].includes(this.$route.name)){ 
				// await this.getAnnouncements();
			} else {
				await this.getSoftwareUpdates();
			}

			this.$root.$on('refresh_index_announcements', () => {
				this.resetIndexAnnouncements(true);
			});
		},
		methods: {
			async getSoftwareUpdates() {
				this.loaded = false;
				let typeList = ''
				this.typeUrlShow = ''

				if(['software-updates', 'software-updates-show'].includes(this.$route.name)) {
					typeList = 'updates'
					this.typeUrlShow = 'software-updates-show'
				} else if (['work-in-progress', 'work-in-progress-show'].includes(this.$route.name)) {
					typeList = 'work_in_progress'
					this.typeUrlShow = 'work-in-progress-show'
				}

				await axios.get('/software-updates', { params: { type: typeList }})
				.then(({data}) => {
					let listData = data.data;
					this.softwareUpdates = listData;

					if(this.$route.name == 'software-updates' || this.$route.name == 'work-in-progress') {
						if(listData.length) {
							if(listData.find(el => !el.seen)) {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData.find(el => !el.seen).id }})
							} else {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData[0].id }})
							}
						} else {
							this.$root.$emit('showEmptyUpdates')
						}
					}
				})
				.finally(() => {
					this.loaded = true
				})
			},
			async getAnnouncements(forceRefresh = false) {
				this.loaded = false;
				this.typeUrlShow = 'announcements-show';

				await axios.get(`${this.$auth.user().instance.id}/announces`)
				.then(({data}) => {
					let listData = data.data
					this.softwareUpdates = listData

					if(['announcements'].includes(this.$route.name) || forceRefresh) {
						if(listData.length) {
							if(listData.find(el => !el.seen)) {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData.find(el => !el.seen).id }})
							} else {
								this.$router.push({ name: this.typeUrlShow, params: { id: listData[0].id }})
							}
						} else {
							this.$root.$emit('showEmptyAnnouncement')
						}
					}
				})
				.finally(() => {
					this.loaded = true
				})
			},
			infiniteAnnouncementsHandler($state) {
				this.typeUrlShow = 'announcements-show';
				
				var paramsCall = { view_more: this.page * 20}
        		this.queryObject(paramsCall)

        		axios.get(`${this.$auth.user().instance.id}/announces`, {params: paramsCall})
        		.then(({data}) => {
        			if(data.data.length){
        				this.page++
        				this.softwareUpdates.push(...data.data)
						setTimeout(() => { $state.loaded() }, 200)

						if(['announcements'].includes(this.$route.name) || this.forceRefresh) {
							if(this.softwareUpdates.length) {
								if(this.softwareUpdates.find(el => !el.seen)) {
									this.$router.push({ name: this.typeUrlShow, params: { id: this.softwareUpdates.find(el => !el.seen).id }})
								} else {
									this.$router.push({ name: this.typeUrlShow, params: { id: this.softwareUpdates[0].id }})
								}
							} else {
								this.$root.$emit('showEmptyAnnouncement')
							}
						}
						
						if(data.data.length < 20){
							$state.complete()
						}
					}else{
						$state.complete()

						if(!this.softwareUpdates.length) {
							this.$root.$emit('showEmptyAnnouncement')
						}
					}
        		}).catch(error => {
        			if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
        		}).finally(() => { 
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},
			queryObject(paramsCall){
				var dataQuery = Object.assign({}, this.$route.query);
				
				Object.keys(dataQuery).forEach(key => {
					if(key == 'search'){ paramsCall.search = dataQuery[key] }
				});
			},
			nameOfSidebarList(){
				if(['software-updates', 'software-updates-show'].includes(this.$route.name)){
					return this.$t('software_updates.title_list');
				}else if(['work-in-progress', 'work-in-progress-show'].includes(this.$route.name)){
					return this.$t('software_updates.title_work_in_progress_list');
				}else if(['announcements', 'announcements-show'].includes(this.$route.name)){
					return this.$t('announcements.title_list');
				}
			},
			resetIndexAnnouncements(forceRefresh = false) {
				this.forceRefresh = forceRefresh;
				this.loaded = false;
				this.page = 0
				this.softwareUpdates = []
				if(this.$refs.infiniteLoading){
					this.$refs.infiniteLoading.status = 1
					this.$refs.infiniteLoading.$emit('infinite', this.$refs.infiniteLoading.stateChanger)
				}
			}
		}
	};
</script>