<template>
	<div class="modal_info modal_evaluate_responsibility">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">{{ task_key_result.name }}</div>
			</div>

			<div class="subheader-data">
				<div class="item" v-if="task_key_result.deadline">
					{{ $t('general.date') }}: {{ task_key_result.deadline | moment('DD MMMM YYYY') }}
				</div>
                <div class="item">
					{{ $t('tasks_general.status') }}:
                    <span class="status" :class="{completed: task_key_result.done === 1}">{{ task_key_result.done === 1 ? $t('tasks_general.completed') : $t('tasks_general.unaccomplished') }}</span>
				</div>
                <div class="separator"></div>
                <div class="item">
                    {{ $t('tasks_general.objective') }}: {{ task_key_result.key_result.objective }}
				</div>
                <div class="separator"></div>
                <div class="item">
                    {{ $t('tasks_general.key_result') }}: {{ task_key_result.key_result.name }}
                </div>
			</div>

			<div class="button-actions">
				<button class="btn-tbf grey center" @click="closeModal">
					<div class="text">{{ $t('general.close') }}</div>
				</button>

				<button id="buttonUpdate" class="btn-tbf center" @click="checkTask()" :class="[task_key_result.done === 1 ? 'green' : 'blue']">
                    <div class="loader-spin" v-if="loadingSubmit"></div>
					<div class="text" v-else>{{ task_key_result.done === 1 ? $t('tasks_general.completed') : $t('general.finish') }}</div>
				</button>
			</div>
		</template>
		<loader-show-responsibility v-else />
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'

	export default {
		data() {
			return {
				loaded: false,
				task_key_result: {},
                loadingSubmit: false
			};
		},
		props: {
            data: Object
        },
        components: {
        	IconClose,
        	LoaderShowResponsibility,
        },
		mounted() {
			this.getTaskKeyResultData()
		},
		methods: {
			getTaskKeyResultData() {
                axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/tasks/${this.data.id}`)
				.then(({data}) => {
					this.task_key_result = data.data
				})
				.finally(() => {
					this.loaded = true
				})
			},
			closeModal(){
				this.$emit("close_modal");
			},
            checkTask() {
                var buttonSubmit = document.getElementById('buttonUpdate');
				buttonSubmit.disabled = true;
                this.loadingSubmit = true;

                var done = this.task_key_result.done ? 0 : 1; 

                axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/tasks/${this.task_key_result.id}`, { deadline: this.task_key_result.deadline, done: done, name: this.task_key_result.name })
                .then(() => {
                    this.$root.$emit('refreshSplitView');
                    this.$root.$emit('refresh_ToDo_List');
                    this.$emit("close_modal");
                })
                .catch(error =>{
					buttonSubmit.disabled = false;
                    this.loadingSubmit = false;
				})
            }
		}
	};
</script>