<template>
<div class="modal-tbf modal-full-page">
    <div class="container-modal opacity-page" v-if="loaded">
        <div class="header-modal">
            <div class="title">
                {{ $t('sync_calendar.choose_calendar') }}
            </div>
            <div class="actions">
                <button class="btn-tbf white only-icon close-btn" @click="cancelGoogle"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="info-content">
            <div class="container-content-modal">
                <div class="form-section">
                	<div class="input-group w-100 calendar_list_item" v-for="calendar in data.list">
                		<label class="checkbox-tbf">
                			<input type="radio" :value="calendar.id" v-model="selectedCalendar">
                			<span class="checkmark"></span>
                			<span class="text-checkbox">{{ calendar.summary }}</span>
                		</label>
					</div>
                </div>
                <div class="form-submit role">
                    <button class="btn-tbf grey center" @click="cancelGoogle">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.cancel')}}</div>
                    </button>
                    <button id='buttonChooseCalendar' class="btn-tbf blue center" @click="saveAction('create')">
                        <div class="loader"></div>
                        <div class="text">{{$t('general.save')}}</div>
                    </button>
                </div>
            </div>
        </div>

		<div id="submodalContainer" class="submodal-container" v-if="subModalShow">
			<div class="overlay-submodal"></div>
			<modal-confirm-close-sync id="submodal_confirm_close" v-if="show_confirm_close" @close_modal="closeModalConfirmed" @cancel_close="cancelClose" />
			<modal-completed-google-sync id="submodal_completed_google_sync" v-if="show_completed_google_sync" @close_modal="closeModalConfirmed" />
		</div>
	</div>
</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import { required } from 'vuelidate/lib/validators'
	import ModalConfirmCloseSync from '../Modals/ConfirmCloseSync'
	import ModalCompletedGoogleSync from '../Modals/CompletedGoogleSync'

	export default {
		props: {
			data: Object
		},
		data() {
			return {
                selectedCalendar: '',
				loaded: false,
				subModalShow: false,
                show_confirm_close: false,
                show_completed_google_sync: false,
			};
		},
        components: {
            IconClose,
			ModalConfirmCloseSync,
			ModalCompletedGoogleSync
        },
        validations: {
			selectedCalendar: {required},
		},
		mounted() {
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)

			this.$root.$on("confirm_close", () => {
                this.cancelGoogle()
            });
		},
		methods: {
			cancelGoogle(){
				this.subModalShow = true
                this.show_confirm_close = true
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.add('active')
                    setTimeout(() => {
                        document.getElementById('submodal_confirm_close').classList.add('active')
                    }, 200);
                }, 0);
			},
            saveAction(type){
				var buttonName = `buttonChooseCalendar`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()
				if(!this.$v.$invalid){
					axios.post('/sync-google-calendar', {access_token: this.data.access_token, calendar_id: this.selectedCalendar})
					.then(() => {
						btnSubmitLoader.classList.add('finish')
						setTimeout(()=>{
							btnSubmitText.innerHTML = this.$t('btn-submit.success')
							btnSubmit.classList.add('completed')
							btnSubmitLoader.classList.remove('onProgress', 'finish')
							btnSubmit.classList.remove('loading')
							setTimeout(()=>{
								this.$auth.fetch();

								this.subModalShow = true
								this.show_completed_google_sync = true
								setTimeout(() => {
									document.getElementById('submodalContainer').classList.add('active')
									setTimeout(() => {
										document.getElementById('submodal_completed_google_sync').classList.add('active')
									}, 200);
								}, 0);
							}, 1000)
						}, 300)
					})
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.choose')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            cancelClose(){
                document.getElementById('submodal_confirm_close').classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.show_confirm_close = false
                    }, 200);
                }, 200);
            },
		}
	};
</script>

<style lang="scss">
	#modal_calendar_list {
	    max-width: 600px;
	    .calendar_list_item{
	    	.text-checkbox{
	    		font-size: 14px;
	    		color: $primary;
	    	}
	    }
	}
</style>