<template>
	<div class="modal-tbf modal-change-subscription">
		<div id="opacityModal" class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $t('subscription.title_activation') }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<transition name="slide" mode="out-in">
					<step-registration v-if="activeSlide == 'step_registration'" :user="user" @change_slide="changeSlide" />
					<step-payment v-if="activeSlide == 'step_payment'" :user="user" :newPrices="newPrices" :selectedPackage="selectedPackage" @change_slide="changeSlide" @close_modal="closeModal"/>
				</transition>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import StepRegistration from "./RequiredStepRegistration"
	import StepPayment from "./RequiredStepPayment"

	export default {
		data() {
			return {
				loaded: false,
				activeSlide: 'step_registration',
				selectedPackage: {package_id: 0},
				newPrices: [],
				user: {
					has_company: 1,
					company_name: '',
					company_cui: '',
					complet_name: '',
					cnp: '',
					cupon_code: '',
					cui_valid: false				
				}
			}
		},
		props: {
			data: Object
		},
		components: {
			IconClose,
			StepRegistration,
			StepPayment
		},
		mounted(){
			setTimeout(() => {
				this.loaded = true
				setTimeout(() => {
					$('.opacity-page').addClass('show')
				}, 0)
			}, 0)
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			changeSlide(slide, data = false){
				if(data.selectedPackage){
					this.selectedPackage = data.selectedPackage
					this.newPrices = data.newPrices
				}

				this.activeSlide = slide
			}
		}
	}
</script>