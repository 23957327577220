import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,

    state() {
        return {
            blocks: [],
            users: [],
            projects: []
        };
    },
    actions: {
        addBlock({commit}, {block, postionToPush}) {
            commit('ADD_BLOCK', {block, postionToPush});
        },
        editBlock({commit}, block) {
            commit('EDIT_BLOCK', block);
        },
        deleteBlock({commit}, block) {
            commit('DELETE_BLOCK', block);
        },
        editEntireBlocks({commit}, blocks) {
            commit('EDIT_ENTIRE_BLOCKS', blocks);
        },
        setUsers({commit}, users) {
            commit('SET_USERS', users);
        },
        setProjects({commit}, projects) {
            commit('SET_PROJECTS', projects);
        }
    },

    getters: {
    	blockId: (state) => (id) => { 
    		return state.blocks.find(el => el.id == id);
    	},
    	blocksFilter: (state) => (section) => {
    		return state.blocks.filter(el => el.section == section );
    	},
    },

    mutations: {
    	ADD_BLOCK(state, {block, postionToPush}) {
            state.blocks.splice(postionToPush, 0, block);
    	},
        EDIT_BLOCK(state, block) {
            var newArray = state.blocks.map(obj => {
                if(obj.id === block.id) {
                    return block;
                }
                return obj;
            })

            state.blocks = newArray;
        },
        DELETE_BLOCK(state, block) {
            state.blocks.splice(state.blocks.findIndex((b) => b.id == block.id), 1);
        },
        EDIT_ENTIRE_BLOCKS(state, blocks) {
            state.blocks = blocks;
        },
        SET_USERS(state, users) {
            state.users = users;
        },
        SET_PROJECTS(state, projects) {
            state.projects = projects;
        }
    }
}