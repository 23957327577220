<template>
	<div class="modal_info payment_error">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/payment-problem-icn.svg"></div>
			<div class="title">{{ $t('subscription.error_payment_large') }}</div>
		</div>
		<div class="description-modal">{{ $auth.user().is_entrepreneur ? $t('subscription.grace_period_desc') : $t('subscription.grace_period_desc_user') }}</div>

		<div class="button-actions">
			<button class="btn-tbf grey center" @click="closeModal" v-if="!$auth.user().is_entrepreneur">
				<div class="text">{{ $t('general.ok_thanks') }}</div>
			</button>
			<button class="btn-tbf blue center" @click="changeModal" v-if="$auth.user().is_entrepreneur">
				{{ $t('subscription.change_method') }}
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {};
		},
		mounted() {},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			changeModal(){
				this.$emit("close_modal");
				this.$router.push({name: 'settings-subscriptions'});
			},
		}
	};
</script>