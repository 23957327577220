<template>
	<div class="modal_info modal_evaluate_responsibility">
		<template v-if="loaded">
			<div class="header-submodal">
				<div class="title">{{ responsibility.name }}</div>
			</div>

			<div class="subheader-data">
				<div class="item" v-if="data.date">
					{{ $t('general.date') }}: {{ data.date | moment('DD MMMM YYYY') }}
				</div>
				<div class="item">
					{{ $t('roles.role') }}: {{ responsibility.role_name }}
				</div>
			</div>

			<div class="description-modal" v-html="responsibility.description" v-if="responsibility.description"></div>

			<div class="trigger" v-if="responsibility.trigger">
				<div class="title">{{ $t('general.trigger')}}</div>
				<div class="description">{{ responsibility.trigger }}</div>
			</div>

			<div class="linked-procedures" v-if="responsibility.procedures && responsibility.procedures.length && loadedProcedures">
				<div class="title-section">{{ $t('responsibility.connected_procedures') }}</div>
				<div class="procedure-run-block" v-for="procedure_run in responsibility.procedures" 
				:set="procedure_data = proceduresTracking.filter(el => el.procedure.id == procedure_run.id)"
				:class="classStatusProcedureRun(procedure_run.id)">
                    <div class="name view">{{ procedure_run.name }}</div>
					<template v-if="procedure_data.find(el => el.status == 'closed' && el.completed == 1)">
						<div class="tracking">
							<div class="icon"><icon-check /></div>
						</div>
					</template>
					<template v-else-if="procedure_data.find(el => el.status == 'open')">
						<div class="tracking">
							<span class="percentage" :set="data_procedure_find = procedure_data.find(el => el.status == 'open')">
								{{ `${data_procedure_find.checked_steps.length}/${data_procedure_find.total_steps}` }}
							</span>
						</div>
					</template>
					<template v-else>
						<div class="tracking">
							<div class="icon" >
								<icon-play-circle />
							</div>
						</div>
					</template>
				</div>
			</div>

			<div class="target" v-if="responsibility.type == 'value'">
				<div class="label">{{ $t('responsibility.target_responsibility') }}: </div>
				<div class="value">{{ $t('responsibility.progress_type_text.' + responsibility.progress_type, { to: parseFloat(responsibility.target), unit: responsibility.unity}  ) }}</div>
			</div>
            
			<div class="button-actions">
				<button class="btn-tbf center" :class="[data.from == 'user' ? 'grey' : 'blue']" @click="closeModal">
					<div class="text">{{ $t('general.close') }}</div>
				</button>
			</div>
		</template>
		<loader-show-responsibility v-else />
	</div>
</template>

<script>
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'
	import IconCheck from '../Icons/CheckLight'
	import IconPlayCircle from '../Icons/LearnProcedures'

	export default {
		data() {
			return {
				loaded: false,
				loadedProcedures: false,
				responsibility: {},
				proceduresTracking: [],
			};
		},
		props: {
            data: Object
        },
        components: {
        	LoaderShowResponsibility,
			IconCheck,
			IconPlayCircle
        },
		mounted() {
			this.getDataResponsibility()
		},
		methods: {
			getDataResponsibility() {
				var paramsHeader = {}

				if(this.data.from == 'user'){
					paramsHeader.user_id = this.data.userId
					paramsHeader.date = this.data.date
				}

				axios.get(`/role-responsibilities/${this.data.id}/show`, { params: paramsHeader })
				.then(({data}) => {
					this.responsibility = data.data

					if(data.data.procedures && data.data.procedures.length && this.data.from == 'user'){
						axios.get(`${process.env.VUE_APP_PROCEDURES_LINK}/api/${this.data.userSlug}/procedure-checks`, { params: { procedures_ids: data.data.procedures.map(el => el.id) } })
						.then(({data}) => {
							this.proceduresTracking = data.data;
						})
						.finally(() => {
							this.loaded = true
							this.loadedProcedures = true
						})
					} else {
						this.loaded = true
					}
				})
			},
			closeModal(){
				this.$emit("close_modal");
			},
			classStatusProcedureRun(procedureID) {
				if(this.proceduresTracking.filter(el => el.procedure.id == procedureID).find(el => el.status == 'closed' && el.completed == 1)) {
					return 'completed';
				} else if (this.proceduresTracking.filter(el => el.procedure.id == procedureID).find(el => el.status == 'open')) {
					return 'on-progress';
				}
			},
		}
	};
</script>