<template>
	<div class="modal_info confirm-close">
		<div class="description-modal">{{ $t('add_meeting.choose_type')}}</div>

		<div class="button-actions">
			<button class="btn-tbf blue center" @click="openTask">
				<div class="text">{{ $t('dashboard.task')}}</div>
			</button>
			<button class="btn-tbf blue center" @click="openMeeting">
				<div class="loader"></div>
				<div class="text">{{ $t('add_meeting.meeting') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {

			};
		},
		async mounted() {
			
		},
        props: {
            data: Object
        },
		methods: {
			openTask(){
				this.$root.$emit('change_modal', 'add_task', this.data, false);
			},
			openMeeting(){
				this.$root.$emit('change_modal', 'add_meeting', this.data, false);
			},
		}
	};
</script>