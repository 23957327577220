<template>
	<div class="container-content-modal">
		<div class="choose-period-packages">
			<div class="title">{{ $t('subscription.choose_period') }}</div>
			<div class="toogle-period">
				<button @click="changePackagesPeriod('year')" :class="{ active: priceTypes == 'year' }">{{ $t('subscription.year') }}</button>
				<button @click="changePackagesPeriod('month')" :class="{ active: priceTypes == 'month' }">{{ $t('subscription.month') }}</button>
			</div>
		</div>

		<div class="list-packages">
			<div class="item-package" v-for="packageItem,index in packagesList[priceTypes]">
				<div class="box" v-bind:class="{active: packageItem.id == selectedPackage.id, 'error-selected': $v.selectedPackage.$error}" @click="selectPlan(packageItem)">
					<div class="header">
						<div class="title">{{ packageItem.name }}</div>
						<div class="selected-check"><icon-check /></div>
					</div>
					<div class="price-box">
						<div class="value">
							<span class="old-price" v-if="packageItem.old_price_in_euro != packageItem.price_in_euro">{{ packageItem.old_price_in_euro }}</span>
							{{ packageItem.price_in_euro + ' EUR' }}
						</div>
						<div class="period">/ {{ packageItem.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }}</div>
					</div>
					<div class="list-features">
						<div class="item-feature" v-for="textFe in packageItem.includes">
							<div class="text">{{ textFe }}</div>
							<div class="icon-square"><icon-check /></div>
						</div>
					</div>
					<div class="action">
						<button class="btn-tbf" v-bind:class="[packageItem.id == selectedPackage.id ? 'blue' : 'white']" @click="selectPlan(packageItem)">
							<span class="text">{{ $t('subscription.select') }}</span>
						</button>
					</div>
				</div>
			</div>
		</div>

		<div class="form-section">
			<!-- Date facturare -->
			<div class="input-group w-mob-100 w-50">
				<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'company'}" @click="changeTypePerson('company')">
					<div class="type">
						<span class="title">{{ $t('subscription.company_title') }}</span>
						<span>{{ $t('subscription.team') }}</span>
					</div>
					<div class="checkboxes-group">
						<label class="checkbox-tbf">
							<input type="radio" value="company" v-model="selectedTypePerson" hidden>
							<span class="checkmark"></span>
						</label>					
					</div>
				</div>
			</div>
			<div class="input-group w-mob-100 w-50">
				<div class="personal-choice" v-bind:class="{active: selectedTypePerson == 'individual'}" @click="changeTypePerson('individual')">
					<div class="type">
						<span class="title">{{ $t('subscription.individual') }}</span>
						<span>{{ $t('subscription.personal') }}</span>
					</div>
					<div class="checkboxes-group">
						<label class="checkbox-tbf">
							<input type="radio" value="individual" v-model="selectedTypePerson" hidden>
							<span class="checkmark"></span>
						</label>					
					</div>
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'individual'">
				<div class="label-header">
					<label class="label">{{ $t('subscription.complet_name') }}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.complet_name.$error, complete: user.complet_name != '' && !$v.user.complet_name.$error}">
					<div class="icon-left"><icon-people /></div>
					<div class="icon-right" v-if="user.complet_name != ''" @click.stop="user.complet_name = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.complet_name')" class="input-text" v-model="user.complet_name" ref="first_input">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'individual'">
				<div class="label-header">
					<label class="label">{{$t('subscription.cnp')}}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.cnp.$error, complete: user.cnp != '' && !$v.user.cnp.$error }">
					<div class="icon-left"><icon-cnp /></div>
					<div class="icon-right" v-if="user.cnp != ''" @click.stop="user.cnp = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.cnp')" class="input-text" v-model="user.cnp">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'company'">
				<div class="label-header">
					<label class="label">{{$t('subscription.company')}}</label>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.company_name.$error, complete: user.company_name != '' && !$v.user.company_name.$error}">
					<div class="icon-left"><icon-building /></div>
					<div class="icon-right" v-if="user.company_name != ''" @click.stop="user.company_name = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.company')" class="input-text" v-model="user.company_name">
				</div>
			</div>

			<div class="input-group w-mob-100 w-50" v-if="selectedTypePerson == 'company'">
				<div class="label-header">
					<label class="label">{{ $t('subscription.cui') }}</label>
					<div class="error-msg" v-if="invalidCui">{{ $t('subscription.cui_invalid') }}</div>
				</div>
				<div class="input-box" v-bind:class="{has_error: $v.user.company_cui.$error, complete: user.company_cui != '' && !$v.user.company_cui.$error}">
					<div class="icon-left"><icon-cui /></div>
					<div class="icon-right" v-if="user.company_cui != ''" @click.stop="user.company_cui = ''"><icon-close /></div>
					<input type="text" :placeholder="$t('subscription.cui')" class="input-text" v-model="user.company_cui">
				</div>
			</div>

			<!-- <div class="input-group w-50">
				<div class="label-header">
					<label class="label">{{ $t('subscription.coupon.label') }}</label>
				</div>
				<div class="input-with-btn">
					<div class="input-box">
						<div class="icon-left"><icon-password /></div>
						<input type="text" v-model="user.coupon_code" :placeholder="$t('subscription.coupon.coupon-placeholder')" class="input-text">
					</div>
					<div class="btn-right">
						<button id="buttonCode" class="btn-tbf grey center" @click="checkCode">
							<div class="loader"></div>
							<div class="text">{{ $t('subscription.coupon.apply') }}</div>
						</button>
					</div>
				</div>
			</div> -->
		</div>

		<div class="submit-form submit-with-check">
			<div class="input-group checkboxes-group terms-condition">
				<label class="checkbox-tbf" v-bind:class="{'error': $v.terms_and_conditions.$error}">
					<span class="text-checkbox" href="https://tbf.ro/termeni-si-conditii-tbf-digital/" v-html="$t('subscription.terms', {terms_link: 'https://tbf.ro/termeni-si-conditii-tbf-digital/'})">
					</span>
					<input type="checkbox" value="1" v-model="terms_and_conditions">
					<span class="checkmark"></span>
				</label>						
			</div>
			<div class="submit-button-box">
				<button id="buttonNextStep" class="btn-tbf blue center" @click="goNextStep">
					<div class="loader-spin" v-if="loadingSubmit"></div>
					<div class="text">{{$t('register.next_step')}}</div>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	import IconClose from "../Icons/Close"
	import IconPeople from "../Icons/User"
	import IconCnp from '../Icons/Cnp'
	import IconBuilding	from '../Icons/Building'
	import IconCui	from '../Icons/Cui'
	import IconCheck from '../Icons/Check'
	import IconPassword from '../Icons/Password'
	import { required, minLength, requiredIf, sameAs } from 'vuelidate/lib/validators'

	export default {
		data(){
			return {
				optionsCountry: [],
				value_country: '',
				selectedTypePerson: 'company',
				invalidCui: '',
				terms_and_conditions: '',
				selectedPackage: {package_id: 0},
				packagesList: [],
				newPrices: [],
				priceTypes: 'month',
				loadingSubmit: false
			}
		},
		props:{
			user: Object,
			soft: Object
		},
		components: {
			IconClose,
			IconPeople,
			IconBuilding,
			IconCnp,
			IconCui,
			IconCheck,
			IconPassword
		},
		validations: {
			selectedPackage: {required},
			selectedTypePerson: {required},
			terms_and_conditions: { sameAs: val => val === true },
			user: {
				company_name: {
			        requiredIf: requiredIf((user) => {
			          return user.has_company === 1 || user.has_company === '' 
			        })
			    },
				company_cui: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 1 || user.has_company === '' 
			        })
				},
				complet_name: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 0
			        })
				},
				cnp: {
					requiredIf: requiredIf((user) => {
			          return user.has_company === 0 
			        }), 
					minLength: minLength(13)
				},
			}
		},
		async mounted() {
			this.user.complet_name = this.$auth.user().last_name + ' ' + this.$auth.user().first_name
			await this.getPackages();
		},
		methods: {
			async getPackages(){
				await axios.get(`/global-packages`)
				.then(({data}) => {
					this.packagesList = data.data.packages;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					if ( this.$auth.user().instance ) {
						var mergePackages =  [...this.packagesList.month, ...this.packagesList.year];
						this.selectedPackage = mergePackages.find( el => el.id == this.$auth.user().instance.package_id ) ? mergePackages.find( el => el.id == this.$auth.user().instance.package_id ) : {};
					}

					// if(this.data.application.status != 'not_instance'){
					// 	if(this.data.application.package_id && this.packagesList.find(el => el.check_package_id == this.data.application.package_id)){
					// 		let currentPackage = this.packagesList.find(el => el.check_package_id == this.data.application.package_id)
					// 		this.selectedPackage = currentPackage
					// 	}else{
					// 		this.selectedPackage = {package_id: 0}
					// 	}
					// }else{
					// 	this.selectedPackage = {package_id: 0}
					// }
				})
			},
			async getCountries(){
				await axios.get('/countries').then(({data}) => {
					this.optionsCountry = data.data
					this.value_country = this.optionsCountry.find(el => el.iso == this.user.language.toUpperCase())
					this.user.country = this.value_country
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			changePackagesPeriod(newPeriod) {
				this.priceTypes = newPeriod;
			},
			goNextStep(){
				var buttonName = 'buttonNextStep'
                var btnSubmit = document.getElementById(buttonName);
                btnSubmit.disabled = true;
				this.loadingSubmit = true;

				this.$v.$touch()
				if(this.$v.$invalid){
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				}else{
					if(this.user.has_company){
						this.checkVatNumber(btnSubmit)
					}else{
						this.submitRegister(btnSubmit)
					}
				}
			},
			checkCode(){
	            var buttonName = 'buttonCode'
	            var btnSubmit = document.getElementById(buttonName);
	            btnSubmit.disabled = true

	            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
	            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

	            btnSubmit.classList.add('loading')
	            btnSubmitLoader.classList.add('onProgress')
	            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

	            this.errorCoupon = false

	            if(this.user.coupon_code != ''){
		            axios.post(`${this.linkSoft}validate-coupon`, {coupon : this.user.coupon_code})
		            .then((data) => {
		                if(data.data.is_valid){
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
		                        btnSubmit.classList.add('completed')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('completed')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-success')
		                            this.newPrices = data.data.values
		                        }, 1000)
		                    }, 300)
		                }else{
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                        btnSubmit.classList.add('error')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('error')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                            this.errorCoupon = true
		                            this.newPrices = []
		                            this.user.coupon_code = null;
		                        }, 1000)
		                    }, 300)
		                }
		            })
		            .catch(error => {
		                btnSubmitLoader.classList.add('finish')
		                setTimeout(()=>{
		                    btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                    btnSubmit.classList.add('error')
		                    btnSubmitLoader.classList.remove('onProgress', 'finish')
		                    btnSubmit.classList.remove('loading')
		                    setTimeout(()=>{
		                        btnSubmit.classList.remove('error')
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                        this.errorCoupon = true
		                        this.newPrices = []
		                        this.user.coupon_code = null;
								if(error.response.status == 500){
									this.error_message = this.$t('error.500')
								}
		                    }, 1000)
		                }, 300)
		            })
		            .finally(() => {
		                setTimeout(()=>{
		                    btnSubmit.disabled = false
		                }, 1500)
		            })
	            }else{
	            	btnSubmitLoader.classList.add('finish')
	            	setTimeout(()=>{
	            		btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
	            		btnSubmit.classList.add('error')
	            		btnSubmitLoader.classList.remove('onProgress', 'finish')
	            		btnSubmit.classList.remove('loading')
	            		setTimeout(()=>{
	            			btnSubmit.classList.remove('error')
	            			btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
	            			this.errorCoupon = true
		                    btnSubmit.disabled = false
	            		}, 1000)
	            	}, 300)
	            }
	        },
			submitRegister(btnSubmit){
				this.invalidCui = false;

				let formData = {
					country: 'RO',
					has_company: this.user.has_company,
					agree_with_terms: this.terms_and_conditions,
					package_token: this.selectedPackage.token,
					// coupon: this.user.coupon_code
				}
				if(this.user.has_company){
					formData.company_name = this.user.company_name
					formData.cui = this.user.formatedCui
				}else{
					formData.name = this.user.complet_name
					formData.cnp = this.user.cnp
				}

				axios.post(`/instances/store`, formData).then((result) => {
					this.$auth.fetch();
					this.$emit('change_slide', 'step_payment', {selectedPackage: this.selectedPackage, newPrices: this.newPrices});
				})
				.catch(error => { 
					if(error.response.status == 500){
						this.error_message = this.$t('error.500')
					}
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
				})
			},
			selectCountry(value, id){
				this.user.country = value
			},
			changeTypePerson(type) {
				this.selectedTypePerson = type;
				if(this.selectedTypePerson == 'company'){
					this.user.has_company = 1;
				}else{
					this.user.has_company = 0;
				}
			},
			selectPlan(packageItem){
                this.selectedPackage = packageItem
				this.user.package_token = packageItem.token;
			},
			checkVatNumber(btnSubmit){
				var cui_number = this.user.company_cui.replace(/[^0-9]/g,'')
				var cui_country = this.user.company_cui.replace(/[^a-zA-Z]+/g, '').toUpperCase()

				if(!cui_country.length){
					cui_country = 'RO'
				}

				this.user.formatedCui = `${cui_country}${cui_number}`
				setTimeout(() => this.submitRegister(btnSubmit), 100)
			}
		}
	}
</script>