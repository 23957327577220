<template>
	<div class="modal-tbf modal-full-page">
        <div class="container-modal opacity-page">
            <template v-if="loaded">
                <div class="header-modal no-padding">
                    <div class="title">{{ $t('key_results.updates') }}</div>
                    <div class="actions">
                        <button class="btn-tbf blue center btn-space-mr" @click="changeModal('add_kr_update')">
                            <div class="text">{{ $t('general.update') }}</div>
                        </button>
                        <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                    </div>
                </div>

                <div class="data-list kr-updates">
                    <div class="row-space-tbf header-list">
                        <div class="content">
                            <div class="column-filter column-date">
                                <div class="text">{{ $t('key_results.date_update') }}</div>
                            </div>
                            <div class="column-filter column-value">
                                <div class="text">{{ $t('key_results.value') }}</div>
                            </div>
                            <div class="column-filter column-progress">
                                <div class="text">{{ $t('key_results.progress') }}</div>
                            </div>
                            <div class="column-filter column-description">
                                <div class="text">{{ $t('key_results.mention') }}</div>
                            </div>
                        </div>
                    </div>

                    <template v-for="entry in updates">
                        <div class="row-space-tbf row-list-item m-h" :key="`entryItem${entry.id}`">
                            <div class="content">
                                <div v-if="$resize && $mq.above(781)" class="border-hover"></div>
                                <div class="column-simple-text column-date">
                                    <div class="text">{{ moment(entry.created_at).format('DD MMM YYYY') }}</div>
                                </div>
                                <div class="column-simple-text column-value">
                                    <div class="text">{{ entry.value }} {{ entry.unity }}</div>
                                </div>
                                <div class="column-simple-text column-progress">
                                    <div class="text">{{ entry.key_result_percent }}%</div>
                                </div>
                                <div class="column-simple-text column-description">
                                    <div class="text">{{ entry.description }}</div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </template>
            <loader-show-responsibility v-else />
        </div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import LoaderShowResponsibility from '@/components/PagesLoaders/ShowResponsibility'

	export default {
		data() {
			return {
				loaded: true,
                updates: []
			};
		},
		props: {
            data: {}
        },
        components: {
        	IconClose,
        	LoaderShowResponsibility,
        },
		mounted() {
            this.getUpdates();
		},
		methods: {
			closeModal(){
				this.$emit("close_modal");
			},
            getUpdates(){
                axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/public/${this.$auth.user().master_instance_id}/key-results/${this.data.keyResult.id}/key-result-logs`)
                .then(({data}) => {
                    this.updates = data.data;
                })
                .finally(() => {
                    setTimeout(() => {
                        $('.opacity-page').addClass('show')
                    }, 0)
                    this.loaded = true;
                })
            },
            changeModal(modalType) {
                this.$root.$emit('change_modal', modalType, {keyResult: this.data.keyResult, meetingId: this.data.meetingId});
            }
		}
	};
</script>

<style lang="scss">
    .data-list.kr-updates {
        .column-date{
            flex: 0 0 150px;
        }
        .column-value{
            flex: 0 0 130px;
        }
        .column-progress{
            flex: 0 0 100px;
        }
        .column-description{
            flex: 1;
        }
    }
</style>