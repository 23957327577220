<template>
    <div class="modal_info modal_delete">
		<div class="header-submodal with-btn">
			<div class="title">{{ data.name }}</div>
            <div class="buttons-actions">
                <button class="btn-tbf white-red bg-red only-icon" @click="deleteTag()"><icon-trash class="icon" /></button>
                <div class="button-close">
                    <button class="btn-tbf white only-icon close-btn" @click="cancelClose()"><icon-close class="icon-close" /></button>
                </div>
            </div>
		</div>
        <div class="form-section">
            <div class="input-group w-100">
                <div class="label-header">
                    <div class="label">Schimba numele</div>
                </div>
                <div class="input-box flex bg-white">
					<div class="icon-left"><icon-tag /></div>
                    <input type="text" :placeholder="$t('general.name_placeholder')" class="input-text" v-model="name">
                    <div class="form__input no-padding">
                        <v-swatches
                            v-model="color_code"
                            show-fallback
                            fallback-input-type="color"
                            swatch-size="20"
                            popover-x="left"
                            row-length="8"
                        ></v-swatches>
                    </div>
                </div>
            </div>
        </div>
		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClose()">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonCreateDepartment" class="btn-tbf blue center" @click="updateTag()">
				<div class="loader-spin" v-if="loadingSaveTask"></div>
				<div class="text" v-else>{{ $t('general.update') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconTag from '../Icons/Tag'
    import IconTrash from '../Icons/Trash'

	import VSwatches from 'vue-swatches'
	import 'vue-swatches/dist/vue-swatches.css'

	export default {
		components: {
            IconClose,
            IconTag,
            IconTrash,
            VSwatches
		},
		data() {
			return {
                name: '',
				color_code: '',
                loadingSaveTask: false
			};
		},
		props: {
			data: Object
		},
		mounted() {
            this.color_code = this.data.color_code
            this.name = this.data.name
		},
		methods: {
			cancelClose(){
				this.$emit("cancel_close");
			},
            refreshFilters(){
                this.$emit("refresh_filters");
                this.cancelClose()
            },
            deleteTag(){
                axios.delete(`/tags/${this.data.id}`)
                .then(() => {
                    this.refreshFilters()
                })
            },
            updateTag(){
                this.loadingSaveTask = true
                axios.patch(`/tags/${this.data.id}`, {
                    name: this.name,
                    color_code : this.color_code
                })
                .then(() => {
                    this.loadingSaveTask = false
                    this.refreshFilters()
                })

            }
		}
	};
</script>