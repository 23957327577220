<template>
	<div class="modal-tbf modal-full-page">
		<div class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="image">
					<img :src="user.avatar" v-if="user.avatar">
					<div class="user-circle" v-else>
						<icon-user />
					</div>
				</div>
				<div class="title">
					<div class="name">{{ this.activeForm == 'congratulations' ? $t('user-details.title-congratulation') : $t('user-details.title-warning') }} {{ `${user.first_name} ${user.last_name ? user.last_name : ''}` }}</div>
				</div>
				<div class="actions">
					<button class="btn-tbf white only-icon close-btn" @click="backRoute"><icon-close class="icon-close" /></button>
				</div>

			</div>
			<div class="form-group no-padding">
				<div class="form">
					<div class="input-group" :class="this.activeForm == 'congratulations' ? 'w-100' : 'w-50'">
						<div class="label-header">
							<label class="label">{{$t('user-details.responsibilities')}}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedResponsibility.$error}">
							<div class="icon-left"><icon-list /></div>
							<multiselect 
							v-model="selectedResponsibility"
							:options="responsibilities"
							:allow-empty="false"
							:show-labels="false"
							:multiple="false" 
							:close-on-select="true"
							track-by="id"
							label="name"
							group-values="responsibilities"
							group-label="role"
							:group-select="false"
							:disabled="!selectedUser"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{$t('user-details.choose_responsibility')}}
									</span>
								</template>
								<template slot="noResult">{{ $t('general.no_result') }}</template>
								<template slot="noOptions">{{ $t('general.empty_list') }}</template>
							</multiselect>
						</div>
					</div>
					<div class="input-group w-50" v-if="this.activeForm == 'warnings'">
						<div class="label-header">
							<label class="label">{{ $t('user-details.date_non-realization') }}</label>
						</div>
						<div class="flex">
							<div class="input-box bg-white w-flex">
								<div class="icon-left"><icon-calendar /></div>
								<FunctionalCalendar
									v-model="dateSelected"
									class="calendar-tbf"
									:configs="configsDateOfFinish"
								>
									<template v-slot:datePickerInput="props">
										<input
										class="vfc-single-input custom-input-picker"
										type="text"
										:value="moment(props.selectedDate,'D/M/YYYY').format('DD MMM YYYY')" 
										v-if="Object.keys(dateSelected).length"/>

										<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('user-details.choose_date') }}</div>
									</template>
								</FunctionalCalendar>
							</div>
						</div>
					</div>

					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{ $t('user-details.description') }}*</label>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.description.$error}">
							<textarea v-model="description" class="input-text" rows="10"></textarea>
						</div>
					</div>
				</div>
			</div>

			
			<div class="form-submit">
				<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
				<button class="btn-tbf grey" @click="backRoute()"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonCreate" class="btn-tbf blue" @click="saveAction()">
					<div class="loader"></div>
					<span class="text">{{ $t('general.add') }}</span>
				</button>
			</div>
		</div>
        <department-loader-modal v-else/>
	</div>
</template>

<script>
	import IconArrowBack from '../Icons/ArrowLong'
	import IconClose from '../Icons/Close'
    import IconPeople from '../Icons/People'
	import IconList from '../Icons/Responsibility'
	import IconUser from '../Icons/User'
	import IconCalendar from '../Icons/Calendar'
	import LoaderUsersCreate from '../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'
	import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue'

	export default {
		components: {
			IconArrowBack,
			IconUser,
			IconClose,
            IconPeople,
			IconList,
			IconCalendar,
			LoaderUsersCreate,
            DepartmentLoaderModal
		},
		data() {
			return {
				loaded: false,
                error_message: '',
				description: '',
                users: [],
				selectedUser: '',
                responsibilities: [],
				selectedResponsibility: '',
				activeForm: '',
				user: {},
				dateSelected: {},
				configsDateOfFinish: {
					isDatePicker: true,
					isDateRange: false,
					isModal: true,
					isAutoCloseable: true,
					changeMonthFunction: true,
					changeYearFunction: true,
                    disabledDates: ['afterToday'],
					limits: {
						min: '',
						max: ''
					}
				},
			};
		},
		props: {
			data: Object
		},
		async mounted(){
			this.user = this.data.user
			this.activeForm = this.data.activeForm
			this.selectedUser = this.user;
			this.getResponsabilities();
		},
		validations: {
            description: {required},
            selectedUser: {required},
            selectedResponsibility: {required}
		},
		methods: {
			backRoute() {
				this.$emit('close_modal');
				if(this.activeForm == 'congratulations'){
					this.$root.$emit('refreshCongratulationsIndex');
				}
				if(this.activeForm == 'warnings'){
					this.$root.$emit('refreshWarningsIndex');
				}
			},
			saveAction(){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
				this.error_message = '';

				this.$v.$touch()
				if(!this.$v.$invalid){
					let objData = {}
					objData.user_id = this.selectedUser.id
                    objData.body = this.description
                    objData.role_responsibility_id = this.selectedResponsibility.id
                    objData.type = this.activeForm
					objData.date = this.dateSelected.selectedDate ? moment(this.dateSelected.selectedDate,'D/M/YYYY').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')

					this.submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML =this.$t('general.add')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            submitStore(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/reviews/store', objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						setTimeout(()=>{
							this.backRoute()
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.add')
						}, 1000)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			getResponsabilities(){
				axios.get(`instances/${this.$auth.user().instance.id}/filter`, { params: {user_responsibilities: true, userId: this.user.id} })
        		.then(({data}) => {
        			this.responsibilities = data.data.responsibilities
        			this.loaded = true
						setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
        		})
			}
		}
	};
</script>