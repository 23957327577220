<template>
    <div class="modal-tbf modal-full-page modal_add_promise">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">{{ $t('add_promise_modal.title')}}</div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>
            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{ $t('add_promise_modal.desc_promise') }}*</label>
                            </div>
                            <div class="input-box bg-white" v-bind:class="{has_error: $v.promiseDay.name.$error}">
                                <textarea placeholder="Care este cel mai important lucru pe care il vei realiza azi pentru rezultatele cheie si obiectivele de care esti responsabil?" v-model="promiseDay.name"></textarea>
                            </div>
                        </div>

                        <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{ $t('add_promise_modal.keyResults') }}*</label>
                            </div>
                            <div class="input-box" v-bind:class="{has_error: $v.selected_key_result.$error}">
                                <div class="icon-left"><icon-tags/></div>
                                <!-- <input type="text" placeholder="Selecteaza unul sau mai multe rezultate cheie" class="input-text no-icon"> -->
								<multiselect 
								v-model="selected_key_result"
								:options="optionsKeyResults"
								class="select-tags-tbf"
								:allow-empty="false"
								:show-labels="false"
								label="name"
								track-by="id"
								:max-height="100"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
								>
									<template slot="placeholder" slot-scope="props"><span class="text">{{ $t('add_promise_modal.phKeyResults') }}</span></template>
									<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
								</multiselect>
                            </div>
                        </div>
                    </div>
                    <div class="form-submit">
                        <button id="buttonAddPromise" class="btn-tbf blue center" @click="savePromise()">
                            <div class="loader"></div>
                            <div class="text">{{ $t('add_promise_modal.savePromise') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <department-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
    import IconClose from '../Icons/Close'
    import IconTags from '../Icons/Tags'
	import { required } from 'vuelidate/lib/validators'

    import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue' 

    export default{
        data() {
            return{
                loaded: false,
                selected_key_result: [],
                optionsKeyResults: [],
				promiseDay: {
					name: '',
				},
            }
        },
        components: {
            IconClose,
            IconTags,
            DepartmentLoaderModal
        },
        validations: {
            promiseDay: {
				name: {required},
			},
            selected_key_result: {required}
        },
        mounted(){
            this.getUserKeyResults()
        },
        methods: {
			closeModal(){
				this.$emit("close_modal");
			},
			getUserKeyResults(){
				axios.get(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/instances/${this.$auth.user().master_instance_id}/filter/external`, { params: { key_results: true } })
				.then(({data}) => {
					this.optionsKeyResults = data.data.key_results
                    this.loaded = true
                    setTimeout(() => {
                        $('.opacity-page').addClass('show')
                    }, 0)
				})
			},
            savePromise(){
				var buttonName = `buttonAddPromise`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.$v.$touch()

				if(!this.$v.$invalid){
                        axios.post(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/promises/store`, {
                            key_result_ids: this.selected_key_result.map(el => el.id),
                            name: this.promiseDay.name
                            }).then((data) => {
                                this.$root.$emit('refresh_objective');
				                this.$root.$emit('refresh_timeline');
                                btnSubmitLoader.classList.add('finish')
                                setTimeout(()=>{
                                    btnSubmitText.innerHTML = this.$t('btn-submit.success')
                                    btnSubmit.classList.add('completed')
                                    btnSubmitLoader.classList.remove('onProgress', 'finish')
                                    btnSubmit.classList.remove('loading')
                                    setTimeout(()=>{
                                        this.closeModal()
                                    }, 500)
                                }, 300)
                            }).catch(error => {
                            btnSubmitLoader.classList.add('finish')
                            setTimeout(()=>{
                                btnSubmitText.innerHTML = this.$t('btn-submit.error')
                                btnSubmit.classList.add('error')
                                btnSubmitLoader.classList.remove('onProgress', 'finish')
                                btnSubmit.classList.remove('loading')
                                setTimeout(()=>{
                                    btnSubmit.classList.remove('error')
                                    btnSubmitText.innerHTML = this.$t('general.add')
                                    btnSubmit.disabled = false
                                }, 1000)
                            }, 300)
                        })
                    }else{
                        btnSubmitLoader.classList.add('finish')
                        setTimeout(()=>{
                            btnSubmitText.innerHTML = this.$t('btn-submit.error')
                            btnSubmit.classList.add('error')
                            btnSubmitLoader.classList.remove('onProgress', 'finish')
                            btnSubmit.classList.remove('loading')
                            setTimeout(()=>{
                                btnSubmit.classList.remove('error')
                                btnSubmitText.innerHTML = this.$t('general.add')
                                btnSubmit.disabled = false
                            }, 1000)
                        }, 300)
                    }
            }
        }
    }
</script>
