<template>
	<div class="modal-tbf modal-change-subscription">
		<div id="opacityModal" class="container-modal opacity-page" v-if="loaded">
			<div class="header-modal">
				<div class="title">
					{{ $auth.user().instance.status == 'new' ? $t('subscription.title_activation') : $t('subscription.title_renew') }}
				</div>
				<div class="actions" @click="closeModal">
					<div class="btn-tbf white only-icon close-btn"><icon-close class="icon-close" /></div>
				</div>
			</div>

			<div class="info-content">
				<div class="choose-period-packages" v-if="isGlobalPackage">
					<div class="title">{{ $t('subscription.choose_period') }}</div>
					<div class="toogle-period">
						<button @click="changePackagesPeriod('year')" :class="{ active: priceTypes == 'year' }">{{ $t('subscription.year') }}</button>
						<button @click="changePackagesPeriod('month')" :class="{ active: priceTypes == 'month' }">{{ $t('subscription.month') }}</button>
					</div>
				</div>

				<div class="container-content-modal">
					<div class="alert-tbf red" v-if="stripeError != ''">
						<div class="data">
							<div class="title">
								{{ stripeError }}
							</div>
						</div>
					</div>

					<div class="list-packages">
						<template v-if="isGlobalPackage">
							<div class="item-package" v-for="packageItem,index in packagesList[priceTypes]">
								<div class="box" v-bind:class="{active: packageItem.id == selectedPackage.id, 'error-selected': $v.selectedPackage.$error}" @click="selectPlan(packageItem)">
									<div class="header">
										<div class="title">{{ packageItem.name }}</div>
										<div class="selected-check"><icon-check /></div>
									</div>
									<div class="price-box">
										<div class="value">
											<span class="old-price" v-if="packageItem.old_price_in_euro != packageItem.price_in_euro">{{ packageItem.old_price_in_euro }}</span>
											{{ packageItem.price_in_euro + ' EUR' }}
										</div>
										<div class="period">/ {{ packageItem.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }}</div>
									</div>
									<div class="list-features">
										<div class="item-feature" v-for="textFe in packageItem.includes">
											<div class="text">{{ textFe }}</div>
											<div class="icon-square"><icon-check /></div>
										</div>
									</div>
									<div class="action">
										<button class="btn-tbf" v-bind:class="[packageItem.id == selectedPackage.id ? 'blue' : 'white']" @click="selectPlan(packageItem)">
											<span class="text">{{ $t('subscription.select') }}</span>
										</button>
									</div>
								</div>
							</div>
						</template>
						<template v-else>
							<div class="item-package">
								<div class="box active" v-bind:class="{'error-selected': $v.selectedPackage.$error}">
									<div class="header">
										<div class="title">{{ selectedPackage.package_name }}</div>
										<div class="selected-check"><icon-check /></div>
									</div>
									<div class="price-box">
										<div class="value">
											<span class="old-price" v-if="selectedPackage.old_price_in_euro != selectedPackage.price_in_euro">{{ selectedPackage.old_price_in_euro }}</span>
											{{ selectedPackage.price_in_euro + ' EUR' }}
										</div>
										<div class="period">/ {{ selectedPackage.interval == 'month' ? $t('general.monthly') : $t('general.yearly') }}</div>
									</div>
									<div class="list-features">
										<div class="item-feature" v-for="textFe in selectedPackage.includes">
											<div class="text">{{ textFe }}</div>
											<div class="icon-square"><icon-check /></div>
										</div>
									</div>
								</div>
							</div>
						</template>
					</div>

					<div class="form-section">
						<!-- Date facturare -->
						<div class="card-details">
							<div class="input-group w-100">
								<div class="label-header">
									<label class="label">{{$t('subscription.card_number')}}</label>
									<div class="error-msg" v-if="cardErrors.cardNumber">{{ cardErrors.cardNumber }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_number.$error, complete: card.card_number != '' && !$v.card.card_number.$error}">
									<div class="icon-left"><icon-card /></div>
									<div class="icon-right" v-if="card.card_number != ''" @click.stop="card.card_number = ''"><icon-close class="icon-clear" /></div>
									<input type="text" placeholder="**** **** **** **** 2452" class="input-text" v-model="card.card_number" v-cardformat:formatCardNumber maxlength="19">
								</div>
							</div>
							<div class="input-group" :class="[{'w-100': $resize && $mq.below(500)}, {'w-50': $resize && $mq.between([501, 768])}, , {'w-60': $resize && $mq.above(769)}]">
								<div class="label-header">
									<label class="label">{{ $t('subscription.validity_date') }}</label>
									<div class="error-msg" v-if="cardErrors.cardExpiry">{{ cardErrors.cardExpiry }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_expiration.$error, complete: card.card_expiration != '' && !$v.card.card_expiration.$error}">
									<div class="icon-left"><icon-date /></div>
									<div class="icon-right" v-if="card.card_expiration != ''" @click.stop="card.card_expiration = ''"><icon-close class="icon-clear" /></div>
									<input type="tel" placeholder="10 / 2020" class="input-text" v-model="card.card_expiration" v-cardformat:formatCardExpiry maxlength="9">
								</div>
							</div>
							<div class="input-group" :class="[{'w-100': $resize && $mq.below(500)}, {'w-50': $resize && $mq.between([501, 768])}, , {'w-40': $resize && $mq.above(769)}]">
								<div class="label-header">
									<label class="label">{{ $t('subscription.cvc') }}</label>
									<div class="error-msg" v-if="cardErrors.cardCvc">{{ cardErrors.cardCvc }}</div>
								</div>
								<div class="input-box" v-bind:class="{has_error: $v.card.card_cvc.$error, complete: card.card_cvc != '' && !$v.card.card_cvc.$error}">
									<div class="icon-left"><icon-password /></div>
									<div class="icon-right" v-if="card.card_cvc != ''" @click.stop="card.card_cvc = ''"><icon-close class="icon-clear" /></div>
									<input type="tel" placeholder="***" class="input-text" v-model="card.card_cvc" v-cardformat:formatCardCVC maxlength="4">
								</div>
							</div>
						</div>

						<div class="coupon-box">
							<!-- <div class="input-group w-100">
								<div class="label-header">
									<label class="label">{{ $t('subscription.coupon.label') }}</label>
								</div>
								<div class="input-with-btn">
									<div class="input-box">
										<div class="icon-left"><icon-password /></div>
										<input type="text" v-model="coupon_code" :placeholder="$t('subscription.coupon.coupon-placeholder')" class="input-text">
									</div>
									<div class="btn-right">
										<button id="buttonCode" class="btn-tbf grey center" @click="checkCode">
											<div class="loader"></div>
											<div class="text">{{ $t('subscription.coupon.apply') }}</div>
										</button>
									</div>
								</div>
							</div> -->

							<div class="submit-form">
								<button id="buttonSubmitPayment" class="btn-tbf blue center" @click="submitPayment">
									<div class="loader-spin" v-if="loadingSubmit"></div>
									<div class="text" v-else>{{$t('subscription.btn-pay')}}</div>
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../Icons/Close"
	import IconCheck from '../Icons/Check'
	import IconPassword from '../Icons/Password'
	import IconCard	from '../Icons/Card'
	import IconDate from '../Icons/Date'
	import { required } from 'vuelidate/lib/validators'

	export default {
		data() {
			return {
				loaded: false,
				loadingSubmit: false,
				selectedPackage: {},
				newPrices: [],
				card:{
					card_number: '',
					card_expiration: '',
					card_cvc: ''
				},
				cardErrors: {},
				stripeError: '',
				packagesList: [],
				coupon_code: '',
				cui_valid: false,
				linkSoft: '',
				priceTypes: 'month',
				isGlobalPackage: false
			}
		},
		components: {
			IconClose,
			IconCheck,
			IconPassword,
			IconCard,
			IconDate
		},
		validations: {
			selectedPackage: {required},
			card: {
				card_number: {required},
				card_expiration: {required},
				card_cvc: {required}
			}
		},
		props: {
			data: Object
		},
		async mounted(){
			if( this.$auth.user().instance.global_stripe === 1 && this.$auth.user().display_package ) {
				this.isGlobalPackage = true;
				await this.getPackages();
			} else {
				await this.getSelectedOldPackage();
			}
		},
		methods: {
			checkUserVocuher(){
				if(this.$auth.user().voucher){
					this.coupon_code = this.$auth.user().voucher
					this.checkCode()
				}
			},
			changePackagesPeriod(newPeriod) {
				this.priceTypes = newPeriod;
			},
			closeModal(){
				this.$emit("close_modal");
			},
			async getPackages(){
				await axios.get(`/global-packages`)
				.then(({data}) => {
					this.packagesList = data.data.packages;
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					if ( this.$auth.user().instance ) {
						var mergePackages =  [...this.packagesList.month, ...this.packagesList.year];
						this.selectedPackage = mergePackages.find( el => el.id == this.$auth.user().instance.package_id ) ? mergePackages.find( el => el.id == this.$auth.user().instance.package_id ) : {};

						this.priceTypes = Object.keys(this.selectedPackage).length ? this.selectedPackage.interval : 'month';
					}
					// if(this.data.application.status != 'not_instance'){
					// 	if(this.data.application.package_id && this.packagesList.find(el => el.check_package_id == this.data.application.package_id)){
					// 		let currentPackage = this.packagesList.find(el => el.check_package_id == this.data.application.package_id)
					// 		this.selectedPackage = currentPackage
					// 	}else{
					// 		this.selectedPackage = {package_id: 0}
					// 	}
					// }else{
					// 	this.selectedPackage = {package_id: 0}
					// }

					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							document.getElementById('opacityModal').classList.add('show')
							// this.checkUserVocuher()
						}, 0)
					}, 0)
				})
			},
			async getSelectedOldPackage() {
				this.linkSoft = this.data && this.data.soft_link ? this.data.soft_link : '/';

				axios.get(`${this.linkSoft}packages`, { params: { package_id: this.data.soft.package_id } })
                .then(({data}) => {
                    this.selectedPackage = data.data.length ? data.data[0] : '';
                })
				.finally(() => {
					this.loaded = true
					setTimeout(() => {
						document.getElementById('opacityModal').classList.add('show')
					}, 0)
				})
			},
			submitPayment(){
				var buttonName = 'buttonSubmitPayment'
				var btnSubmit = document.getElementById(buttonName);

				this.loadingSubmit = true;
				btnSubmit.disabled = true;
				
				this.$v.$touch();

				this.cardErrors = {}
				this.stripeError = ''
				var cardError = false
				// validate card number
				if(!this.$cardFormat.validateCardNumber(this.card.card_number)){
					this.cardErrors.cardNumber = this.$t('validator.invalid_credit_card');
					cardError = true
				};

				// validate card expiry
				if (!this.$cardFormat.validateCardExpiry(this.card.card_expiration)) {
					this.cardErrors.cardExpiry = this.$t('validator.invalid_credit_card_date');
					cardError = true
				};

				// validate card CVC
				if (!this.$cardFormat.validateCardCVC(this.card.card_cvc)) {
					this.cardErrors.cardCvc = this.$t('validator.invalid_credit_card_cvc');
					cardError = true
				};

				if(this.$v.$invalid || cardError){
					this.loadingSubmit = false;
					btnSubmit.disabled = false;
				}else{
					if ( this.$auth.user().instance.global_stripe === 1 ) {
						this.changeSubscription(btnSubmit)
					} else {
						this.paymentAction(btnSubmit)
					}
				}
			},
			changeSubscription(btnSubmit){
				axios.post(`${this.linkSoft}change-plan-account`, {package_token: this.selectedPackage.token})
				.then(({data}) => {
					this.paymentAction(btnSubmit)
				})
				.catch(error => {
					this.loadingSubmit = false;
					btnSubmit.disabled = false;
				})
			},
			paymentAction(btnSubmit){
				let paymetFormData = {
					number: this.card.card_number,
					exp_month: this.card.card_expiration.substring(0, 2),
					exp_year: this.card.card_expiration.substring(5, 9),
					cvc: this.card.card_cvc
					// coupon: this.coupon_code
				}

				paymetFormData.package_id = this.selectedPackage.id

				if( this.$auth.user().instance.global_stripe === 1 ) {
					this.storeGlobalPayment(btnSubmit, paymetFormData);
				} else {
					this.storeOldPayment(btnSubmit, paymetFormData);
				}
			},
			storeOldPayment(btnSubmit, paymetFormData) {
				axios.post(`${this.linkSoft}payment`, paymetFormData)
				.then(({data}) => {
					this.$emit("close_modal");
                    this.$store.dispatch('applications/getApplications');
					this.$auth.fetch();
				})
				.catch(error => {
					let errorData = error.response.data
					if(errorData.subscription && ['requires_source_action', 'requires_action'].includes(errorData.subscription.latest_invoice.payment_intent.status)){
						var sub = errorData.subscription;

						var stripeKey = ''
						switch (this.data.soft.application.name) {
							case 'Organigrama':
								stripeKey = process.env.VUE_APP_STRIPEPUBLISHABLE_KEY_ORGANIGRAMA
								break;
							case 'Obiective':
								stripeKey = process.env.VUE_APP_STRIPEPUBLISHABLE_KEY_OBJECTIVE
								break;
							case 'Proceduri':
								stripeKey = process.env.VUE_APP_STRIPEPUBLISHABLE_KEY_PROCEDURES
								break;
						}

						let stripe = Stripe(stripeKey);
						stripe.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
						.then((result) => {
							if(result.error){
								btnSubmit.disabled = false;
								this.loadingSubmit = false;
								this.stripeError = result.error.message;
							}else{
								axios.post(`${this.linkSoft}set-as-active`)
								.then(({data}) => {
									this.$emit("close_modal");
                    				this.$store.dispatch('applications/getApplications');
									this.$auth.fetch();
								});
							}
						});
					}else{
						btnSubmit.disabled = false;
						this.loadingSubmit = false;
						this.stripeError = error.response.data.message;
					}
				});
			},
			storeGlobalPayment(btnSubmit, paymetFormData) {
				axios.post(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/sync-global-payment`, paymetFormData)
				.then(({data}) => {
					if(data.status == 'error'){
						var responseObj = data.data;

						if(responseObj.subscription && ['requires_source_action', 'requires_action'].includes(responseObj.subscription.latest_invoice.payment_intent.status)){
							var sub = responseObj.subscription;

							let stripe = Stripe(responseObj.publishable_key);
							stripe
							.confirmCardPayment(sub.latest_invoice.payment_intent.client_secret)
							.then((result) => {
								if(result.error){
									this.loadingSubmit = false;
									btnSubmit.disabled = false;
									this.stripeError = result.error.message;
								}else{
									axios.post(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/sync-mark-as-active`)
									.then(({data}) => {
										this.$store.dispatch('applications/getApplications');
										this.$emit("close_modal");
										this.$auth.fetch();
									})
								}
							});
								
						}else{
							this.onPayment = false
							this.stripeError = responseObj.message;

							btnSubmit.disabled = false
							this.loadingSubmit = false
						}
					}else{
						this.$store.dispatch('applications/getApplications');
						this.$emit("close_modal");
						this.$auth.fetch();
					}
				})
				.catch(error => {
					btnSubmit.disabled = false;
					this.loadingSubmit = false;
					this.stripeError = error.response.data.message;
				})
			},
			checkCode(){
	            var buttonName = 'buttonCode'
	            var btnSubmit = document.getElementById(buttonName);
	            btnSubmit.disabled = true

	            var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
	            var btnSubmitText = document.querySelector(`#${buttonName} .text`)

	            btnSubmit.classList.add('loading')
	            btnSubmitLoader.classList.add('onProgress')
	            btnSubmitText.innerHTML = this.$t('btn-submit.loading')

	            this.errorCoupon = false

	            if(this.coupon_code != ''){
		            axios.post(`${this.linkSoft}validate-coupon`, {coupon : this.coupon_code})
		            .then((data) => {
		                if(data.data.is_valid){
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('btn-submit.success')
		                        btnSubmit.classList.add('completed')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('completed')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-success')
		                            this.newPrices = data.data.values
		                        }, 1000)
		                    }, 300)
		                }else{
		                    btnSubmitLoader.classList.add('finish')
		                    setTimeout(()=>{
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                        btnSubmit.classList.add('error')
		                        btnSubmitLoader.classList.remove('onProgress', 'finish')
		                        btnSubmit.classList.remove('loading')
		                        setTimeout(()=>{
		                            btnSubmit.classList.remove('error')
		                            btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
		                            this.errorCoupon = true
		                            this.newPrices = []
		                            this.user.coupon_code = null;
		                        }, 1000)
		                    }, 300)
		                }
		            })
		            .catch(error => {
		                btnSubmitLoader.classList.add('finish')
		                setTimeout(()=>{
		                    btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
		                    btnSubmit.classList.add('error')
		                    btnSubmitLoader.classList.remove('onProgress', 'finish')
		                    btnSubmit.classList.remove('loading')
		                    setTimeout(()=>{
		                        btnSubmit.classList.remove('error')
		                        btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
								if(status == 500) {
									alert(this.$t('error.500'))
								}
				
		                        this.errorCoupon = true
		                        this.newPrices = []
		                        this.user.coupon_code = null;
		                    }, 1000)
		                }, 300)
		            })
		            .finally(() => {
		                setTimeout(()=>{
		                    btnSubmit.disabled = false
		                }, 1500)
		            })
	            }else{
	            	btnSubmitLoader.classList.add('finish')
	            	setTimeout(()=>{
	            		btnSubmitText.innerHTML = this.$t('subscription.coupon.apply-error')
	            		btnSubmit.classList.add('error')
	            		btnSubmitLoader.classList.remove('onProgress', 'finish')
	            		btnSubmit.classList.remove('loading')
	            		setTimeout(()=>{
	            			btnSubmit.classList.remove('error')
	            			btnSubmitText.innerHTML = this.$t('subscription.coupon.apply')
	            			this.errorCoupon = true
		                    btnSubmit.disabled = false
	            		}, 1000)
	            	}, 300)
	            }
	        },
			selectPlan(packageItem){
                this.selectedPackage = packageItem
			}
		}
	}
</script>