<template>
	<div class="form-template">
        <template v-if="loadedApplications">
            <slot></slot>
            <!-- <notifications/> -->
            <div class="overflow-modal" style="position: fixed" v-if="show_overflow">
                <div class="bg-modals" @click="closeModals()"></div>
                <modal-role id="modal_role" v-if="show_modal.role" :data="data.role" @close_modal="closeModal('role')" />
                <modal-workstation id="modal_workstation" v-if="show_modal.workstation" :data="data.workstation" @close_modal="closeModal('workstation')" />
                <modal-department id="modal_department" v-if="show_modal.department" :data="data.department" @close_modal="closeModal('department')"/>
                <modal-skill id="modal_skill" v-if="show_modal.skill" :data="data.skill" @close_modal="closeModal('skill')"/>
                <modal-vacant-job-duration id="modal_vacant_job_duration" v-if="show_modal.vacant_job_duration" :data="data.vacant_job_duration" @close_modal="closeModal('vacant_job_duration')"/>
                <modal-confirm-change-step id="modal_confirm_change_step" v-if="show_modal.confirm_change_step" :data="data.confirm_change_step" @close_modal="closeModal('confirm_change_step')"/>
            </div>
        </template>
        <div class="page-loader-on-start" v-else>
            <div class="loader-spin"></div>
        </div>
	</div>
</template>

<script>
    import ModalRole from "../Modals/Role"
	import ModalWorkstation from "../Modals/Workstation"
	import ModalDepartment from "../Modals/Department"
    import ModalSkill from "../Modals/Skill"
	import ModalVacantJobDuration from "../Modals/VacantJobDuration"
	import ModalConfirmChangeStep from "../Modals/ConfirmChangeStep"
    // import Notifications from "../General/Notifications"

    export default {
        data() {
            return {
                loadedApplications: false,
                show_overflow: false,
                show_modal: {
					role: false,
                    department: false,
                    workstation: false,
                    skill: false,
                    vacant_job_duration: false,
                    confirm_change_step: false
                },
                data: {
                    department: {},
                    vacant_job_duration: {},
                    confirm_change_step: {}
                },
                item: {}
            }
        },
        components: {
            ModalRole,
			ModalWorkstation,
			ModalDepartment,
			ModalSkill,
            ModalVacantJobDuration,
            ModalConfirmChangeStep,
            // Notifications
        },
        mounted(){
            if(this.$store.state.applications.applications.length){
                this.loadedApplications = true;
            } else {
                var refreshIntervalApps = setInterval(() => {
                    if(this.$auth.check()){
                        if(this.$store.state.applications.applications.length){
                            clearInterval(refreshIntervalApps);
                            this.loadedApplications = true;
                        }
                    }
                }, 50);
            }

            this.$root.$on("open_modal", (type, data = false) => {
                this.active_modal = type
                this.data[type] = data ? data : ''

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        $("#modal_" + type).addClass("active")
                    }, 0);
                }, 0);
            })

            this.checkLanguage();
        },
        methods:{ 
            closeModals(){
                var arrayModalsNeedVerification = ['role', 'workstation', 'department', 'skill']
                if(arrayModalsNeedVerification.includes(this.active_modal)){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false){
                var target_modal = type ? type : this.active_modal
                $("#modal_" + target_modal).removeClass("active")

                setTimeout(() => {
                    this.show_modal[target_modal] = false
                    $('.overflow-modal').removeClass('show')
                    setTimeout(() => {
                        this.show_overflow = false;
                    }, 200)
                }, 200);
            },
            checkLanguage(){
                if(this.$auth.check()){
                   this.$i18n.locale = this.$auth.user().language
                   this.$cookies.set('language', this.$auth.user().language, '1m')
                }else if(this.$cookies.get('language')){
                   this.$i18n.locale = this.$cookies.get('language')
                }
            },
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/form-template.scss";
</style>