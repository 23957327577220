<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/trash-icon.svg" /></div>
			<div class="title">{{ $t("delete.title") }}</div>
		</div>
		<div class="description-modal" v-if="data.model && !data.model.is_recurrence">
			{{ $t("delete.question", { text: $t("delete." + data.type) }) }}
			<div class="alert" v-if="data.parent">
				{{ $t("delete.parent_department") }}
			</div>
			{{ $t("delete.atention") }}
		</div>

		<template v-else>
			<div class="description-modal">{{ $t("delete.atention") }} {{ data.type == "meeting" ? "" : $t("delete.delete_task_recurrence") }}</div>
			<div class="form-section">
				<div class="input-group w-100" v-for="optionSave in optionsDeleteActivity">
					<label class="checkbox-tbf type-radio">
						<input type="radio" :value="optionSave" v-model="optionDeleteActivitySelected" />
						<span class="checkmark"></span>
						<span class="text-checkbox">{{ data.type == "meeting" ? $t(`add_meeting.${optionSave}_mode`) : $t(`add_task.${optionSave}_mode`) }}</span>
					</label>
				</div>
			</div>

			<div v-if="$v.optionDeleteActivitySelected.$error" class="error-msg">{{ $t("validator.required_save_mode") }}</div>
		</template>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelDelete">
				<div class="text">{{ $t("general.cancel") }}</div>
			</button>
			<button id="buttonSubmitDelete" class="btn-tbf red center" @click="submitDelete">
				<div class="loader-spin" v-if="loadingSave"></div>
				<div class="text" v-else>{{ $t("delete.delete") }}</div>
			</button>
		</div>
	</div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";

export default {
	data() {
		return {
			loadingSave: false,
			optionsDeleteActivity: ["current", "current_and_future", "all"],
			optionDeleteActivitySelected: "",
		};
	},
	props: {
		data: Object,
	},
	validations: {
		optionDeleteActivitySelected: {
			required: requiredIf(function() {
				return this.data.model && this.data.model.is_recurrence;
			}),
		},
	},
	mounted() {},
	methods: {
		cancelDelete() {
			this.$emit("cancel_close");
			this.$emit("close_modal");
		},
		submitDelete() {
			var buttonName = `buttonSubmitDelete`;
			var btnSubmit = document.getElementById(buttonName);
			this.loadingSave = true;
			btnSubmit.disabled = true;

			switch (this.data.type) {
				case "user":
					this.deleteUser();
					break;
				case "role":
					this.deleteRole();
					break;
				case "work_schedule":
					this.deleteWorkSchedule();
					break;
				case "department":
					this.deleteDepartment();
					break;
				case "work_station":
					this.deleteWorkStation();
					break;
				case "skill":
					this.deleteSkill();
					break;
				case "entry_congratulation":
					this.deleteCongratulation();
					break;
				case "entry_warning":
					this.deleteWarning();
					break;
				case "entry_evaluation":
					this.deleteEvaluation();
					break;
				case "responsibility":
					this.deleteResponsibilty();
					break;
				case "vacantJob":
					this.deleteVacantJob();
					break;
				case "vacation_day":
					this.deleteVacationDay();
					break;
				case "vacantJobAnnouncement":
					this.deleteVacantJobAnnouncement();
					break;
				case "comment":
					this.deleteAnnouncementComment();
					break;
				case "announcement":
					this.deleteAnnouncement();
					break;
				case "task":
					this.deleteTask();
					break;
				case "project":
					this.deleteProject();
					break;
				case "meeting":
					this.deleteMeeting();
					break;
				case "pipeline":
					this.deletePipeline();
					break;
			}
		},
		deleteUser() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/users/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteRole() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/roles/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteWorkSchedule() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/work-schedules/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteDepartment() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/departments/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteWorkStation() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/workstations/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteSkill() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/skills/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteCongratulation() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/reviews/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteWarning() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/reviews/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteEvaluation() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/user-evaluations/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteResponsibilty() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/role-responsibilities/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteVacantJob() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/vacant-jobs/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteVacantJobAnnouncement() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/vacant-job-announce/${this.data.model.id}`)
				.then(() => {
					this.refreshPage();
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteVacationDay() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/vacation-days/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteAnnouncementComment() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/comments/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteAnnouncement() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/announces/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteTask() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			this.$v.$touch();
			if (!this.$v.$invalid) {
				var paramsCall = {};
				paramsCall.date = this.data.model.date;

				if (this.data.model.from_meeting_id) {
					paramsCall.from_meeting_id = this.data.model.from_meeting_id;
					paramsCall.from_meeting_step = this.data.model.from_meeting_step;
					paramsCall.force_update_ws = true;
				} else {
					paramsCall.from_calendar = 1;
				}

				if (this.optionDeleteActivitySelected) {
					paramsCall[this.optionDeleteActivitySelected] = 1;
				} else {
					paramsCall.current = 1;
				}

				axios
					.post(`/tasks/${this.data.model.id}/delete`, paramsCall)
					.then(({ data }) => {
						setTimeout(() => {
							this.refreshPage();
						}, 100);

						// if(this.data.model.from_meeting_id) {
						// this.$root.$emit('updateTaskMeeting', { action: 'destroy', id: data.data.id, type: data.data.type});
						// }
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status == 403) {
								this.$router.push({ name: "forbbiden" });
							} else if (error.response.status == 500) {
								alert(this.$t("error.500"));
								this.loadingSave = false;
								buttonSubmit.disabled = false;
							}
						}
					});
			} else {
				this.loadingSave = false;
				buttonSubmit.disabled = false;
			}
		},
		deleteProject() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/projects/${this.data.model.slug}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		deleteMeeting() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			this.$v.$touch();
			if (!this.$v.$invalid) {
				var paramsCall = {};
				paramsCall.date = this.data.model.date;
				paramsCall.from_calendar = 1;

				if (this.optionDeleteActivitySelected) {
					paramsCall[this.optionDeleteActivitySelected] = 1;
				} else {
					paramsCall.current = 1;
				}

				axios
					.post(`/meetings/${this.data.model.id}/delete`, paramsCall)
					.then(() => {
						setTimeout(() => {
							this.refreshPage();
						}, 100);
					})
					.catch((error) => {
						if (error.response) {
							if (error.response.status == 403) {
								this.$router.push({ name: "forbbiden" });
							} else if (error.response.status == 500) {
								alert(this.$t("error.500"));
								this.loadingSave = false;
								buttonSubmit.disabled = false;
							}
						}
					});
			} else {
				this.loadingSave = false;
				buttonSubmit.disabled = false;
			}
		},
		deletePipeline() {
			var buttonSubmit = document.getElementById("buttonSubmitDelete");

			axios
				.delete(`/pipelines/${this.data.model.id}`)
				.then(() => {
					setTimeout(() => {
						this.refreshPage();
					}, 100);
				})
				.catch((error) => {
					if (error.response) {
						if (error.response.status == 403) {
							this.$router.push({ name: "forbbiden" });
						} else if (error.response.status == 500) {
							alert(this.$t("error.500"));
							this.loadingSave = false;
							buttonSubmit.disabled = false;
						}
					}
				});
		},
		refreshPage() {
			switch (this.data.from) {
				case "index_users":
					this.$root.$emit("refreshUsersIndex");
					break;
				case "show_user":
					this.$router.push({ name: "users" });
					break;
				case "index_roles":
					this.$root.$emit("refreshRolesIndex");
					break;
				case "show_role":
					this.$router.push({ name: "roles" });
					break;
				case "index_work_schedules":
					this.$root.$emit("refreshWrokSchedulesIndex");
					break;
				case "index_departments":
					this.$root.$emit("refreshDepartmentsIndex");
					break;
				case "index_work_stations":
					this.$root.$emit("refreshWorkStationsIndex");
					break;
				case "index_skills":
					this.$root.$emit("refreshSkillsIndex");
					break;
				case "index_congratulations":
					this.$root.$emit("refreshCongratulationsIndex");
					break;
				case "index_evaluations":
					this.$root.$emit("refreshEvaluationsIndex");
					break;
				case "index_warnings":
					this.$root.$emit("refreshWarningsIndex");
					break;
				case "chart":
					this.$root.$emit("refreshCompanyChart");
					break;
				case "role_list":
					this.$root.$emit("refreshShowRole");
					break;
				case "index_vacant_jobs":
					this.$root.$emit("refreshVacantJobsIndex");
					break;
				case "show_vacant_job":
					this.$router.push({ name: "vacant-jobs" });
					break;
				case "show_vacant_job_announcement":
					this.$root.$emit("refreshVacantJobPage");
					break;
				case "index_vacation_days_calendar":
					this.$root.$emit("refresh_vacation_days_calendar");
					break;
				case "index_vacation_days_list":
					this.$root.$emit("refresh_vacation_days_list");
					break;
				case "show_announcement":
					this.$root.$emit("refresh_announcement");
					break;
				case "index_announcements":
					this.$root.$emit("refresh_index_announcements");
					break;
				case "index_tasks":
					this.$root.$emit("refreshSplitView");
					this.$root.$emit("refresh_ToDo_List");
					this.$root.$emit("refreshProjectPage");
					break;
				case "index_projects":
					this.$root.$emit("refreshProjectsIndex");
					break;
				case "index_pipelines":
					this.$root.$emit("refreshPipelinesIndex");
					break;
			}

			setTimeout(() => {
				this.loadingSave = false;
				this.$emit("close_modal");
			}, 300);
		},
	},
};
</script>
