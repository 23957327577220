<template>
    <div class="notifications" id="notificationsList" :class="{group: group, all: !group, start: first_time_loaded, 'to-back': goToGroup}" @click="showAllNotifications">
        <template v-for="(notification, index) in notifications">
            <div class="notification" :style="{'--index': index}" :id="notification.id">
                <div class="avatar" v-if="notification.avatar">
                    <img :src="notification.avatar" >
                </div>
                <div class="icon" v-else-if="notification.frontend_type == 'meetings'">
                    <icon-user/>
                </div>
                <div class="icon" v-else>
                    <icon-bell/>
                </div>
                <div class="content-notification" @click="clickOnNotification(notification)">
                    <p :class="{inGroup: group}" v-html="notification.name"></p>
                </div>
                <div class="close" @click.stop="group ? clearAllNotifications() : removeNotification(notification)">
                    <icon-close/>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import IconClose from "../Icons/Close"
    import IconUser from '../Icons/User'
    import IconBell from '../Icons/Bell'

    export default {
        data() {
            return{
				notifications: [],
                countUnread: 0,
                group: false,
                first_time_loaded: true,
                goToGroup: false,
				procedures_fe_link: process.env.VUE_APP_PROCEDURES_FE_LINK,
				objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK
            }
        },
        components: {
            IconClose,
            IconUser,
            IconBell
        },
        async mounted(){
			await this.getNotifications();

            this.$root.$on("updateNotificationsList", (parsedData) => {
				if(parsedData.for_personal){
					this.notifications.push(parsedData);
				}
			});
            
            window.addEventListener('click', (e) => {   
                if (!document.getElementById('notificationsList').contains(e.target)){
                     if(!this.group && this.group != null) {
                        this.goToGroup = true;
                        setTimeout(() => {
                            this.group = true
                            this.goToGroup = false;
                        }, 200)
                    }
                }
            });
        },
        methods: {
            async getNotifications(){
				await axios.get(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notification-list`).then(({data}) => {
					this.notifications = data.data.notification_logs && data.data.notification_logs.length ? data.data.notification_logs.filter(el => el.seen == 0) : [];
                    if(this.notifications.length > 3){
                        this.group = true
                    }

				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
			},
			viewItem(itemSlug, notification, column){
                this.markAsRead(notification);

				if (notification.frontend_type == 'evaluations') {
					this.$router.push({ name: 'user-show', params: { slug: itemSlug }, query: { tab: 'assessments' }})
				} else if (notification.frontend_type == 'announces') {
					this.$router.push({ name: 'announcements-show', params: { id: itemSlug } })
				} else if (notification.frontend_type == 'tasks') {
                    if(['dashboard', 'dashboard_user'].includes(this.$route.name)){
						this.$root.$emit('open_modal', 'add_task', {
							crud: 'edit',
							taskEditId: notification.entity_slug,
							// type: notification.additional_data.log_type,
							date: notification.additional_data.date
						});
                    } else {
                        this.$router.push({ name: 'dashboard', 
                        query: { open_task: notification.entity_slug, date: notification.additional_data.date} } );
                    }

                    this.$root.$emit('refreshSplitView');
                    this.$root.$emit('refresh_ToDo_List');
                    
				} else if (notification.frontend_type == 'meetings') {
                    if(['dashboard', 'dashboard_user'].includes(this.$route.name)){
						this.$root.$emit('open_modal', 'add_meeting_view_only', {
							crud: 'edit',
							meetingEditId: notification.entity_slug,
							type: notification.additional_data.log_type,
							date: notification.additional_data.date
						});
                    } else {
                        this.$router.push({ name: 'dashboard', params:{ date: notification.additional_data.date, log_type: notification.additional_data.log_type }, query: { open_meeting: notification.entity_slug} } );
                    }
				} else if (notification.frontend_type == 'role_responsibilities') {
                    if(['user-show'].includes(this.$route.name)){
						this.$root.$emit('open_responsibilities');
                    } else {
                        this.$router.push({ name: 'user-show', params: { slug: notification.additional_data.user_slug }, query: { open_responsibilities: 'yes' } })
                    }
				} else if (notification.frontend_type == 'departments') {
					this.$router.push({ name: 'company' })
				} else if (notification.frontend_type == 'procedures') {
					window.location.href = `${this.procedures_fe_link}/procedure/${itemSlug}`
				}else if (notification.frontend_type == 'processes') {
					window.location.href = `${this.procedures_fe_link}/process/${itemSlug}`
				}else {
                    window.location.href = `${this.objectives_fe_link}/obiectiv/${itemSlug}`
                }
			},
			markAsRead(notification){
				axios.patch(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/notifications/${notification.id}`)
				.then(({data}) => {
					var contentNotification = document.querySelector(`#notification-box-${notification.id} .content-notification`)
					if(contentNotification){contentNotification.classList.remove('new')}

                    this.notifications.splice( this.notifications.findIndex(el => el.id == notification.id), 1);
				});
			},
            removeNotification(notification, all){
                const element = document.getElementById(notification.id)
                element.remove()
                if(!all){
                    this.markAsRead(notification)
                }
            },
            showAllNotifications() {
                this.first_time_loaded = false;
                this.group = false;
            },
            clearAllNotifications(){
                this.notifications.map(el => this.removeNotification(el, true));

                axios.post(`${process.env.VUE_APP_CENTRAL_BE_LINK}/api/${this.$auth.user().master_instance_id}/notifications/seen-all`)
            },
            clickOnNotification(notification){
                if(this.group == false){
                    if(notification.need_link){
                        this.viewItem(notification.entity_slug, notification, 'personal');

                    }
                }
            }
        }
    }
</script>
