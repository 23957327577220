<template>
	<div class="full-template">
        <slot></slot>

        <div class="overflow-modal" v-if="show_overflow">
            <div class="bg-modals" @click="closeModals()"></div>
            <modal-company id="modal_company" v-if="show_modal.company" :data="data.company" @close_modal="closeModal('company')"/>
            <modal-user id="modal_user" v-if="show_modal.user" :data="data.user" @close_modal="closeModal('user')"/>
            <modal-department id="modal_department" v-if="show_modal.department" :data="data.department" @close_modal="closeModal('department')"/>
            <modal-delete id="modal_delete" v-if="show_modal.delete" :data="data.delete" @close_modal="closeModal('delete')" />
            <sidebar-org id="modal_sidebar_org" v-if="show_modal.sidebar_org" :data="data.sidebar_org" @close_modal="closeModal('sidebar_org')"/>
            <sidebar-departments id="modal_sidebar_departments" v-if="show_modal.sidebar_departments" @close_modal="closeModal('sidebar_departments')"/>
            <modal-change-subscription id="modal_change_subscription" v-if="show_modal.change_subscription" @close_modal="closeModal('change_subscription')"/>
            <modal-grace-period id="modal_payment_error" v-if="show_modal.payment_error" @close_modal="closeModal('payment_error')"/>
            <modal-user-free-days id="modal_user_free_days" v-if="show_modal.user_free_days" :data="data.user_free_days" @close_modal="closeModal('user_free_days')"/>
            <sidebar-vacant-job id="modal_sidebar_vacant_job" v-if="show_modal.sidebar_vacant_job" :data="data.sidebar_vacant_job" @close_modal="closeModal('sidebar_vacant_job')"/>
			<modal-share-tree-chart id="modal_share_tree_chart" v-if="show_modal.share_tree_chart" @close_modal="closeModal('share_tree_chart')"/>
            <modal-add-task id="modal_add_task" :data="data.add_task" v-if="show_modal.add_task" @close_modal="closeModal('add_task')" />
            <modal-kr-updates id="modal_kr_updates" :data="data.kr_updates" v-if="show_modal.kr_updates" @close_modal="closeModal('kr_updates')"/>
            <modal-add-kr-update id="modal_add_kr_update" :data="data.add_kr_update" v-if="show_modal.add_kr_update" @close_modal="closeModal('add_kr_update')"/>
        </div>
	</div>
</template>

<script>
    import SidebarOrg from "../Pages/Company/SidebarOrg"
    import SidebarDepartments from "../Pages/Company/SidebarDepartments"
    import SidebarVacantJob from "../Pages/Company/SidebarVacantJob"
    import ModalCompany from "../Modals/EditCompany"
    import ModalDepartment from "../Modals/Department"
    import ModalUser from "../Modals/User"
    import ModalChangeSubscription from "../Modals/ChangeSubscription"
    import ModalDelete from "../Modals/Delete"
    import ModalGracePeriod from "../Modals/GracePeriod"
    import ModalUserFreeDays from "../Modals/UserFreeDays"
	import ModalShareTreeChart from "../Modals/ShareTreechart"
    import ModalAddTask from "../Modals/NewAddTask"
    import ModalKrUpdates from '../Modals/KeyResultUpdates'
    import ModalAddKrUpdate from '../Modals/AddKrUpdate'

    export default {
        data() {
            return {
                show_overflow: false,
                active_modal: [],
                show_modal: {
                    sidebar_org: false,
                    delete: false,
                    department: false,
                    user: false,
                    company: false,
                    payment_error: false,
                    change_subscription: false,
                    sidebar_departments: false,
                    user_free_days: false,
                    share_tree_chart: false,
                    add_task: false,
                    kr_updates: false,
                    add_kr_update: false
                },
                data: {
                    sidebar_org: {},
                    delete: {},
                    department: {},
                    user: {},
                    company: {},
                    user_free_days: {},
                    add_task: {},
                    kr_updates: {},
                    add_kr_update: {}
                }
            }
        },
        components: {
            SidebarOrg,
            SidebarDepartments,
            SidebarVacantJob,
            ModalCompany,
            ModalDepartment,
            ModalUser,
            ModalDelete,
            ModalChangeSubscription,
            ModalGracePeriod,
            ModalUserFreeDays,
            ModalShareTreeChart,
            ModalAddTask,
            ModalKrUpdates,
            ModalAddKrUpdate
        },
        mounted(){
            Intercom('shutdown');
            
            this.$root.$on("open_modal_full_template", (type, data = false) => {
                if (this.active_modal.includes(type)){
                    this.active_modal.splice(this.active_modal.indexOf(type), 1)
                }else {
                    this.active_modal.push(type)
                }
                this.data[type] = data ? data : ''

                setTimeout(() => {
                    this.show_overflow = true
                    this.show_modal[type] = true
                    setTimeout(() => {
                        $('.overflow-modal').addClass('show')
                        setTimeout(() => {
                            $("#modal_" + type).addClass("active")
                        }, 100);
                    }, 0);
                }, 0);
            })

            this.$root.$on('change_modal', (type, data = false) => {
                this.data[type] = data ? data : ''
                
                this.active_modal.map(el => { $("#modal_" + el).removeClass("active") })
				setTimeout(() => {
                    this.active_modal.map(el => { this.show_modal[el] = false })
                    this.active_modal = []
					setTimeout(() => {
						this.show_modal[type] = true
						this.active_modal.push(type)
						setTimeout(() => {
							$("#modal_" + type).addClass("active");
						}, 0);
					}, 0);
				}, 200);
            })

            if(this.$auth.check() && this.$auth.user().rights.instance == 'grace_period' && !this.$cookies.get('grace_period_modal')){
                this.$cookies.set('grace_period_modal', true, '1d')
                this.$root.$emit('open_modal_full_template', 'payment_error')
            }

            this.checkLanguage();
        },
        methods: {
            closeModals(){
                var arrayModalsNeedVerification = ['department', 'company', 'add_task']

                if(this.active_modal.find(el => arrayModalsNeedVerification.includes(el))){
                    this.$root.$emit('confirm_close')
                }else{
                    this.closeModal()
                }
            },
            closeModal(type = false){
                var target_modal = type ? type : this.active_modal

                if(Array.isArray(target_modal)) {
                    target_modal.map(el => {
                        $("#modal_" + el).removeClass("active")
                    })
                } else {
                    $("#modal_" + target_modal).removeClass("active")
                }

                setTimeout(() => {
                    if(Array.isArray(target_modal)) {
                        target_modal.map(el => {
                            this.show_modal[el] = false
                        })
                        this.active_modal = []
                    } else {
                        this.show_modal[target_modal] = false
                        this.active_modal.splice(this.active_modal.indexOf(target_modal), 1)
                    }

                    if(this.active_modal.length === 0){
                        $('.overflow-modal').removeClass('show')
                        setTimeout(() => {
                            this.show_overflow = false;
                        }, 200)
                    }
                }, 200);
            },
            checkLanguage(){
                if(this.$auth.check()){
                   this.$i18n.locale = this.$auth.user().language
                   this.$cookies.set('language', this.$auth.user().language, '1m')
                }else if(this.$cookies.get('language')){
                   this.$i18n.locale = this.$cookies.get('language')
                }
            },
        }
    }
</script>

<style lang="scss">
    @import "../../styles/templates/full-template.scss";
    @import "../../styles/pages/organigram.scss";
</style>