<template>
    <div class="sidebar-right-meeting">
        <div class="list-content comments" ref="commentSection">
            <div class="comment-item" v-for="comment in comments">
                <div class="header-comment">
                    <div class="author">
                        <img class="avatar" :src="comment.author.avatar"/>
                        <span class="name">{{ comment.author.name}}</span>
                    </div>

                    <div class="date">{{ moment(comment.created_at).fromNow() }}</div>
                </div>
                <div class="content-comment" v-html="comment.body"></div>
            </div>
        </div>

        <div class="add-comment" v-if="$auth.check()">
            <quill-editor class="editor-tbf h-75" ref="myQuillEditor" v-model="newComment" :options="editorOption" />
            <div class="footer">
                <div class="step">
                </div>
                <div class="action-store">
                    <button class="btn-tbf blue" @click="storeComment">
                        <div class="loader-spin" v-if="loadingStoreComment"></div>
                        <div class="text" v-else>{{ $t('general.add') }}</div>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconTask from '@/components/Icons/Task'
import { debounce } from "debounce";

import "quill-mention";


export default {
    components: {
        IconTask
    },
    props: {
        comments: {
            type: Array,
            required: true
        },
    },
    watch: {
        comments() {
            setTimeout(() => {
                this.scrollToBottom();
            }, 200);
        }
    },
    created() { 
        this.handleDebounceTopic = debounce( () => { this.updateTopic(); }, 1000)
    },
    data() {
        return {
            isLoading: true,
            newComment: '',
            editorOption: {
                placeholder: 'Adauga comentariu...',
                theme: 'bubble',
                modules:{
                    toolbar: [
                        ['bold', 'italic', 'code-block', 'link'],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'color': [] }, { 'background': [] }],
                        ['clean']
                    ],
                    imageUploader: {
                        upload: (file) => {
                            return new Promise((resolve, reject) => {
                                let objData = new FormData()
                                objData.append('file', file)
                                objData.append('type', 'announces')

                                axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData)
                                .then(({data}) => {
                                    resolve(data.file_path);
                                })
                            });
                        },
                    },
                    // mention: {
                    //     allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                    //     mentionDenotationChars: ["@"],
                    //     source: async (searchTerm, renderList) => {
                    //         const matchedPeople = await this.suggestPeople(searchTerm);
                    //         renderList(matchedPeople, searchTerm);
                    //     },
                    //     dataAttributes: ['id', 'name']
                    // }
                }
            },
            optionsUsers: [],
            activeTopic: '',
            loadingStoreComment: false
        }
    },
    async mounted() {
        // if(this.$auth.check()){
        //     await this.getUsers();
        // }

       setTimeout(() => {
            this.scrollToBottom();
       }, 0)
    },
    methods: {
        async getUsers(){
            await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true}})
            .then(({data}) => {
                this.optionsUsers = data.data.users;
            });
        },
        async suggestPeople(searchTerm){
            return this.optionsUsers.filter( (user) => user.name.toUpperCase().includes(searchTerm.toUpperCase())).map((el) => { return {id: el.id, value: el.name} });
        },
        storeComment() {
            this.loadingStoreComment = true;
            
            if(this.newComment === '') {
                this.loadingStoreComment = false;
                return;
            }

            var formData = {
                body: this.newComment,
                task_id: this.$parent.parentTaskId,
            }

            axios.post(`/comments/store`, formData)
            .then(({data}) => {
                this.newComment = '';
                this.$refs.myQuillEditor.quill.setContents([]);
                this.comments.push({...data.data, author: { avatar: this.$auth.user().avatar, name: this.$auth.user().first_name + ' ' + this.$auth.user().last_name }});
            })
            .finally(() => {
                this.loadingStoreComment = false;
            });
        },
        scrollToBottom() {
            const commentSection = this.$refs.commentSection;
            commentSection.scrollTop = commentSection.scrollHeight;
        }
    }
}
</script>