<template>
	<div class="sidebar-org">
		<div class="close-button" v-if="$resize && $mq.above(601)">
			<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
		</div>
		<div class="content-sidebar" v-if="loaded">
			<div class="header">
				<div class="title">
					<div class="text">{{ vacantJob.name }}</div>
				</div>
				<div class="action buttons">
					<router-link :to="{name: 'vacant-jobs-show', params: { slug: vacantJob.slug } }" class="btn-edit show" v-if="$auth.user().rights.admins_crud"><icon-show /></router-link>
					<router-link :to="{name: 'vacant-jobs-edit', params: { slug: vacantJob.slug } }" class="btn-edit" v-if="$auth.user().rights.admins_crud"><icon-edit /></router-link>
					<div class="close-button" v-if="$resize && $mq.below(600)">
						<button class="btn-tbf white only-icon" @click="closeModal"><icon-close /></button>
					</div>
				</div>
			</div>

			<div class="actions-header" v-if="$auth.user().rights.admins_crud">
				<button class="button-simple-header" @click="$router.push({ name: 'user-create', query: { vacant_job: vacantJob.slug } })">
					<div class="text">{{ $t('vacant_jobs.add_to_user') }}</div>
				</button>
			</div>

			<div class="box-label-text" v-if="vacantJob.roles.length">
				<div class="label">{{ $t('general.roles') }}</div>
				<div class="text">{{ vacantJob.roles.map(a => a.name).join(", ") }}</div>
			</div>

			<div class="box-label-text" v-if="vacantJob.departments.length">
				<div class="label">{{ $t('profile.departments') }}</div>
				<div class="text">{{ vacantJob.departments.map(el => { return el.name }).join(", ") }}</div>
			</div>

			<div class="box-label-text" v-if="vacantJob.workstation">
				<div class="label">{{ $t('create-user.workstation') }}</div>
				<div class="text">{{ vacantJob.workstation.name }}</div>
			</div>

			<div class="accordion-sidebar" v-if="vacantJob.roles.find(el => el.responsibilities.length)">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseResponsibilities">
					<div class="icon"><icon-responsibility /></div>

					<div class="title">{{ $t('user-details.responsibilities') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseResponsibilities" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseResponsibilities">
					<div class="box-radius">
						<div class="data" v-for="role in vacantJob.roles">
							<div class="label">{{ role.name }}</div>
							<div class="value" v-for="responsibility in role.responsibilities">{{ responsibility }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="vacantJob.work_schedule">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseWorkSchedules">
					<div class="icon"><icon-work-schedule /></div>

					<div class="title">{{ $t('show_user.work-schedule') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseWorkSchedules" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseWorkSchedules">
					<div class="box-radius">
						<div class="inline-data" v-for="schedule in vacantJob.work_schedule.schedules">
							<div class="label">{{ $t(`general.${schedule.day}`) }}</div>
							<div class="value">{{ `${schedule.start_time} - ${schedule.end_time}` }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="vacantJob.skills.length">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseSkills">
					<div class="icon"><icon-skill /></div>

					<div class="title">{{ $t('show_user.skills') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseSkills" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseSkills">
					<div class="box-radius">
						<div class="inline-data" v-for="skill in vacantJob.skills">
							<v-popover offset="5" trigger="hover" placement="bottom" popoverBaseClass="popover-tbf" class="label" :delay="{show: 100, hide: 0}" >
								<div class="label">{{ skill.name }}</div>
								<template slot="popover">
									<div class="simple-text">{{ skill.description }}</div>
								</template>
							</v-popover>
							<div class="value">{{ $t(`skill.type.${skill.level}`) }}</div>
						</div>
					</div>
				</div>
			</div>

			<div class="accordion-sidebar" v-if="vacantJob.foreign_languages.length">
				<div class="header-accordion collapsed" data-toggle="collapse" data-target="#collapseForeignLanguages">
					<div class="icon"><icon-globe /></div>

					<div class="title">{{ $t('show_user.foreign_languages') }}</div>
					<div class="actions">
						<button class="arrow-collapse show collapsed" type="button" data-toggle="collapse" data-target="#collapseForeignLanguages" aria-expanded="true">
							<icon-arrow />
						</button>
					</div>
				</div>

				<div class="collapse content-accordion" id="collapseForeignLanguages">
					<div class="box-radius">
						<div class="data" v-for="foreign_language in vacantJob.foreign_languages">
							<div class="label">{{ foreign_language.name }}</div>
							<div class="value">{{ foreign_language.levels.map((el) => { return `${ $t('foreign_languages.' + el['type'])}: ${el['level']}`}).join(", ") }}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "../../Icons/Close";
	import IconEdit from "../../Icons/Edit";
	import IconSettings from "../../Icons/Settings";
	import IconUser from "../../Icons/User";
	import IconArrow from "../../Icons/Arrow";
	import IconFile from '../../Icons/FilePdf'
	import IconDepartment from "../../Icons/Department";
	import IconSkill from "../../Icons/Skill";
	import IconFiles from "../../Icons/Files";
	import IconGlobe from "../../Icons/Globe";
	import IconWorkSchedule from "../../Icons/WorkSchedule";
	import IconRole from "../../Icons/Role";
	import IconShow from "../../Icons/Show";
	import IconHobbies from "../../Icons/Hobbies";
	import IconResponsibility from "../../Icons/Responsibility";

	export default {
		data() {
			return {
				loaded: false,
				vacantJob: {}
			};
		},
		props:{
			data: Object
		},
		watch: {
			dataSlug: {
				immediate: true,
				deep: true,
				handler (val) {
					this.getVacantJobData()
				}
			}
		},
		components: {
			IconClose,
			IconEdit,
			IconSettings,
			IconUser,
			IconArrow,
			IconFile,
			IconDepartment,
			IconSkill,
			IconFiles,
			IconGlobe,
			IconWorkSchedule,
			IconRole,
			IconShow,
			IconHobbies,
			IconResponsibility
		},
		mounted() {
			
		},
		methods: {
			async getVacantJobData(){
				await axios.get(`/vacant-jobs/${this.data.slug}/show`)
				.then(({data}) => {
					this.vacantJob = data.data
				})
				.catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
					}, 0)
				})			
			},
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>

<style lang="scss">
	.responsibility{
		margin: 0 0 10px 0;
		padding-left: 15px;
		line-height: 1.3;
		margin-top: 10px;
	}
</style>