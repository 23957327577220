<template>
	<div class="modal_info">
		<div class="header-submodal with-btn">
            <div class="title">{{ $t('meeting_session.reviews') }}</div>
            <div class="button-close">
                <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
            </div>
        </div>

        <div class="list-meeting-reviews" v-if="isLoading">
            <div class="review-item" v-for="reviewItem in reviews">
                <div class="header">
                    <div class="user">
                        <template v-if="reviewItem.added_by">
                            <div class="avatar">
                                <img :src="reviewItem.added_by.avatar" :alt="reviewItem.added_by.name">
                            </div>
                            <div class="name">
                                {{ reviewItem.added_by.name }}
                            </div>
                        </template>
                        <template v-else>{{ reviewItem.email }}</template>
                    </div>
                    <div class="rating">
                        {{ reviewItem.rating }} <icon-stars class="orange" />
                    </div>
                </div>
                <div class="content">
                    <div class="question-answers" v-for="question in JSON.parse(reviewItem.review)" v-if="question.type !== 'rating'">
                        <div class="question">{{ $t(`meeting_session.${question.type}`) }}</div>
                        <div class="answer">{{ question.answer }}</div>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script type="text/javascript">
	import IconClose from "@/components/Icons/Close"
	import IconStars from "@/components/Icons/Stars"


	export default {
		props: {
            meetingId: Number
		},
        components: {
            IconClose,
            IconStars
        },
		data() {
			return {
                isLoading: false,
                reviews: []
            };
		},
		async mounted() {
            await this.getReviews();
        },
		methods: {
            async getReviews() { 
                // Get projects
                axios.get(`/meetings/${this.meetingId}/reviews`)
                .then(({data}) => {
                    this.reviews = data.data;
                })
                .finally(() => {
                    this.isLoading = true;
                })
            },
			closeModal(){
				this.$emit("close_modal");
			}
		}
	};
</script>