import axios from 'axios'
import Vue from 'vue'

export default {
    namespaced: true,

    state() {
        return {
            applications: []
        };
    },

    actions: {
        getApplications({commit}) {
            // if(Vue.cookies.get('userApplicationsDigital')){
            //     console.log('am gasit in cookies')
            // } else {
            //     Vue.cookies.set('userApplicationsDigital', JSON.stringify(["razvan"]), "1d", null,  process.env.VUE_APP_MAIN_DOMAIN);
            // }

            axios.get('/user-applications')
        	.then(({data}) => {
        		commit('SET_APPLICATIONS', data.data)
        	})
        }
    },

    getters: {
    	allApplications(state) { 
    		return state.applications
    	},
    	activeApplications(state) {
    		return state.applications.filter(el => el.is_active)
    	},
        getApplication: (state, getters) => (name) => {
            return getters.allApplications.find(el => el.application.name == name)
        }
    },

    mutations: {
    	SET_APPLICATIONS(state, applications) {
    		state.applications = applications
    	}
    }
}