<template>
	<div class="modal_info modal_delete">
		<div class="header-submodal">
			<div class="image"><img src="/build/images/clone-duotone.svg"></div>
			<div class="title">{{ $t('clone.title') }}</div>
		</div>
		<div class="description-modal">
			{{ $t('clone.question', {text: $t('clone.type.' + data.type)}) }}
		</div>

		<div class="button-actions">
			<button class="btn-tbf white center" @click="cancelClone">
				<div class="text">{{ $t('general.cancel') }}</div>
			</button>
			<button id="buttonSubmitClone" class="btn-tbf blue center" @click="storeClone">
				<div class="loader-spin" v-if="loadingClone"></div>
				<div class="text" v-else>{{ $t('clone.submit') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	export default {
		data() {
			return {
				loaded: false,
				loadingClone: false
			};
		},
		props: {
			data: Object
		},
		mounted() {

		},
		methods: {
			cancelClone(){
				this.$emit("close_modal");
			},
			storeClone(){
				var buttonName = 'buttonSubmitClone'
				var btnSubmit = document.getElementById(buttonName);
				btnSubmit.disabled = true;
				this.loadingClone = true;

				switch (this.data.type) {
					case 'role':
						this.cloneRole(btnSubmit)
						break;
					case 'responsibility':
						this.cloneResponsibility(btnSubmit)
						break;
				}
			},
			cloneRole(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post(`/roles/${this.data.itemId}/clone`)
				.then(({data}) => {
					this.refreshPage()
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1000)
				})
			},
			cloneResponsibility(btnSubmit, btnSubmitLoader, btnSubmitText){
				axios.post(`/role-responsibilities/${this.data.itemId}/clone`)
				.then(({data}) => {
					this.refreshPage()
				})
				.finally(() => {
					setTimeout(()=>{
						this.$emit("close_modal")
					}, 1000)
				})
			},
			refreshPage(){
				switch (this.data.from) {
					case 'index_roles':
						this.$root.$emit('refreshRolesIndex')
						break;
					case 'role_list':
						this.$root.$emit('refreshResponsibilitiesIndex')
						break;
				}
			}
		}
	};
</script>