<template>
    <div class="modal-tbf modal-full-page modal-crud-responsibility">
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{ data.crud == 'edit' ? $t('days_off.edit_title') : $t('days_off.create_title') }}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="info-content">
                <div class="container-content-modal">
                    <div class="form-section">
                    	 <div class="input-group w-100">
                            <div class="label-header">
                                <label class="label">{{$t('general.description')}}</label>
                                <div v-if="$v.name.$error" class="error-msg">{{ $t('validator.required') }}</div>
                            </div>
                            <div class="input-box bg-white department" v-bind:class="{has_error: $v.name.$error}">
                                <div class="icon-right" v-if="name != ''" @click.stop="name = ''"><icon-close class="icon-clear" /></div>
                                <input type="text" :placeholder="$t('general.description_placeholder')" class="input-text no-icon" v-model="name">
                            </div>
                        </div>

                        <div class="input-group w-100 checkboxes-group">
                        	<label class="checkbox-tbf">
                        		<span class="text-checkbox">{{ $t('days_off.for_all') }}</span>
                        		<input type="checkbox" v-model="for_all" @change="resetInputs">
                        		<span class="checkmark"></span>
                        	</label>
                        </div>

                        <div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']">
                        	<div class="label-header">
                        		<label class="label">{{ $t('days_off.type') }}</label>
                                <div v-if="$v.type.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        	</div>
                        	<div class="input-box bg-white" v-bind:class="{has_error: $v.type.$error}">
                        		<div class="icon-left"><icon-calendar /></div>
                        		<multiselect 
                        		v-model="type"
                        		v-bind:class="{populate: type}"
                        		:options="optionsTypes"
                        		:allow-empty="false"
                        		:show-labels="false"
                        		>
	                        		<template slot="placeholder" slot-scope="props">
	                        			<span class="text">
	                        				{{$t('days_off.choose-type')}}
	                        			</span>
	                        		</template>
	                        		<template slot="singleLabel" slot-scope="props">{{ $t(`days_off.options_type.${props.option.value}`) }}</template>
	                        		<template slot="option" slot-scope="props">{{ $t(`days_off.options_type.${props.option.value}`) }}</template>
	                        		<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
	                        	</multiselect>
	                        </div>
                        </div>

                        <div class="input-group" :class="[$resize && $mq.below(600) ? 'w-100' : 'w-50']" v-if="!for_all">
                        	<div class="label-header">
                        		<label class="label">{{ $t('days_off.users') }}</label>
                                <div v-if="$v.selectedUsers.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        	</div>
                        	<div class="input-box bg-white" v-bind:class="{has_error: $v.selectedUsers.$error}">
                        		<div class="icon-left"><icon-user /></div>
                        		<multiselect 
								class="hide-tags multiselect_checkbox"
                        		v-model="selectedUsers"
                        		v-bind:class="{populate: selectedUsers.length}"
                        		:options="optionsUsers"
                        		:allow-empty="false"
                        		:show-labels="false"
                        		:multiple="true"
                        		:close-on-select="false"
                        		track-by="id" 
                        		label="name"
                        		>
                        			<template slot="placeholder" slot-scope="props">
										<span class="text">
											{{ $t('days_off.choose-user') }}
										</span>
									</template>
									<template slot="selection" slot-scope="{ values, search, isOpen }">
										<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
										<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
									</template>
									<template slot="option" slot-scope="props">
										<div class="checkbox">
											<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
										</div>
									</template>
	                        		<template slot="noResult">{{$t('general.no_results')}}</template>
	                        		<template slot="noOptions">{{$t('general.empty_list')}}</template>
	                        	</multiselect>
	                        </div>
                        </div>

                        <div class="input-group w-100">
                    		<div class="label-header">
                    			<label class="label">{{ $t('days_off.select_interval') }}</label>
                    			<div v-if="$v.dateOfYear.$error" class="error-msg">{{ $t('validator.required') }}</div>
                    		</div>
                    		<div class="input-box bg-white-all-time">
                    			<FunctionalCalendar
                    			ref="CalendarDateOfEmployment"
                    			v-model="dateOfYear"
                    			class="calendar-tbf frequency range-picker"
                    			:is-date-range="true"
                    			:is-multiple="true"
                    			:calendars-count="3"
                    			:is-separately="false"
                    			:change-month-step="3"
                    			:hidden-elements="['leftAndRightDays']"
                    			:newCurrentDate="newStartDay"
                    			>
                        			<template v-slot:datePickerInput="props">
                        				<input
                        				class="vfc-single-input custom-input-picker"
                        				type="text"
                        				:value="props.selectedDate ? moment(props.selectedDate, 'YYYY-M-D').format('DD MMMM') : ''" 
                        				v-if="Object.keys(dateOfYear).length"/>

                        				<div class="vfc-single-input custom-placeholder-picker" v-else>{{ $t('create-user.choose-date-of-employment') }}</div>
                        			</template>
                        		</FunctionalCalendar>
                        	</div>
                        </div>
                    </div>

                    <div class="form-submit department">
                        <button class="btn-tbf grey center" @click="closeModal">
                            <div class="loader"></div>
                            <div class="text">{{$t('general.cancel')}}</div>
                        </button>
                        
                        <div class="error-msg" v-if="error_message != ''">{{error_message}}</div>

                        <button id='buttonCreateDayOff' class="btn-tbf blue center" @click="data.crud == 'create' ? saveAction('create') : saveAction('update')">
                            <div class="loader"></div>
                            <div class="text">{{ data.crud == 'create' ? $t('general.add') : $t('general.update')}}</div>
                        </button>
                    </div>
                </div>
            </div>
            
            <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
                <div class="overlay-submodal"></div>
                <modal-confirm-close id="submodal_confirm_close" v-if="submodal.confirm_close" @close_modal="closeModalConfirmed" @cancel_close="closeSubModal('confirm_close')" />
            </div>
    	</div>
        <department-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconCalendar from '../Icons/Calendar'
	import IconClock from '../Icons/Clock'
	import IconProgress from '../Icons/Progress'
	import IconCircleCheck from '../Icons/CircleCheck'
	import IconUser from '../Icons/User'
	import ModalConfirmClose from '../Modals/ConfirmClose'

	import { required, requiredIf } from 'vuelidate/lib/validators'
	import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue'

	export default {
		data() {
			return {
                loaded: false,
                subModalShow: false,
                submodal: {
                	confirm_close: false,
                },
                error_message: '',
                name: '',
                for_all: true,
                type: '',
                optionsTypes: [ { value: 'day_off', for_all: false }, { value: 'sick_leave', for_all: false }, { value: 'without_payment', for_all: false }, { value: 'legal_holiday', for_all: true }, { value: 'company_day_off', for_all: true } ],
                allDay: true,
				dateOfYear: {
					dateRange: { start: '', end: '' }
				},
				limitsRange: {min: '', max: ''},
				newStartDay: '',
				optionsUsers: [],
				selectedUsers: []
			};
		},
        components: {
            IconClose,
            IconCalendar,
            IconClock,
            IconProgress,
            IconCircleCheck,
            IconUser,
            ModalConfirmClose,
            DepartmentLoaderModal
        },
        props: {
            data: Object
        },
        validations: {
			name: {required},
			type: { required },
			dateOfYear: { dateRange: { start: {required}, end: {required} } },
			selectedUsers: { required: requiredIf(function() { return !this.for_all })}
		},
		created(){
			var startDate = moment().startOf('year')
			this.limitsRange.min = moment().startOf('year').format('D/M/YYYY')
			this.limitsRange.max = moment().endOf('year').format('D/M/YYYY')
			this.newStartDay = new Date(startDate)
		},
		async mounted() { 
			await this.getFilters()

            if(this.data.crud == 'edit'){
                await this.getVacationDay();
            }else{
            	this.optionsTypes.map((el) => {
            		if(el.for_all != this.for_all) {
            			el.$isDisabled = true
            		}
            	})

            	this.loaded = true
            	setTimeout(() => {
            		$('.opacity-page').addClass('show')
            	}, 0)
            }

            this.$root.$on("confirm_close", () => {
                if(this.checkInputs()){
                	this.openSubModal('confirm_close', this.submodal.confirm_close)
                }else{
                    this.$emit("close_modal");
                }
            });
		},
		methods: {
			async getFilters() {
				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: {users: true} })
				.then(({data}) => {
					this.optionsUsers = data.data.users
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => { this.loaded = true })
			},
            async getVacationDay(){
				axios.get(`/vacation-days/${this.data.id}/edit`)
                .then(({data})=> {
                	this.name = data.data.name
                	this.for_all = data.data.for_all
                	this.optionsTypes.map((el) => {
                		if(el.for_all != data.data.for_all) {
                			el.$isDisabled = true
                		}
                	})
                	this.type = this.optionsTypes.find(el => el.value == data.data.type)
                	this.selectedUsers = this.optionsUsers.filter(el => data.data.users.map(el => { return el.id }).includes(el.id))

                	this.dateOfYear = {
                		selectedDate: false,
                		selectedDateTime: false,
                		multipleDateRange: [],
                		selectedDatesItem: "",
                		selectedHour: "00",
                		selectedMinute: "00",
                		dateRange: { end: moment(data.data.end_date).format('D/M/YYYY'), start: moment(data.data.start_date).format('D/M/YYYY')},
                		selectedDates: []
                	}
                })
                .finally( () => {
                	this.loaded = true
                	setTimeout(() => {
                		$('.opacity-page').addClass('show')
                	}, 0)
                })
            },
            saveAction(type){
				var buttonName = `buttonCreateDayOff`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.error_message = '';

				this.$v.$touch()

				if(!this.$v.$invalid){
					var objData = {
						name: this.name,
						start_date: moment(this.dateOfYear.dateRange.start, 'D/M/YYYY').format('YYYY-MM-DD'),
						end_date: moment(this.dateOfYear.dateRange.end, 'D/M/YYYY').format('YYYY-MM-DD'),
						type: this.type.value,
						for_all: this.for_all,
						users: this.selectedUsers.length ? this.selectedUsers.map((el) => {return el.id}) : ''
					}

					if(type == 'create'){
						this.createDayOff(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateDayOff(objData, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
            createDayOff(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post(`/vacation-days/store`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						if(this.data.from == 'calendar') {
							this.$root.$emit('refresh_vacation_days_calendar')
						} else {
							this.$root.$emit('refresh_vacation_days_list')
						}
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.create')
                            this.$emit("close_modal");
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
            updateDayOff(objData, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.patch(`/vacation-days/${this.data.id}`, objData)
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						this.$v.$reset()
						if(this.data.from == 'calendar') {
							this.$root.$emit('refresh_vacation_days_calendar')
						} else {
							this.$root.$emit('refresh_vacation_days_list')
						}
						setTimeout(()=>{
							btnSubmit.classList.remove('completed')
							btnSubmitText.innerHTML = this.$t('general.update')
                            this.$emit("close_modal");
						}, 1000)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
            checkInputs(){
                var notEmpty = false

                if(this.name != ''){
                    notEmpty = true
                }

                if(this.type != ''){
                    notEmpty = true
                }

                return notEmpty
            },
            closeModalConfirmed(){
                this.$emit("close_modal");
            },
            closeModal(){
                if(this.checkInputs()){
                	this.openSubModal('confirm_close')
                }else{
                    this.$emit("close_modal");
                }
			},
			openSubModal(type, active_tab = false) {
				if(active_tab) {
					document.getElementById(`submodal_confirm_close`).classList.remove('active')
					this.submodal.confirm_close = false
				}
				
				this.subModalShow = true
				this.submodal[type] = true
				setTimeout(() => {
					document.getElementById('submodalContainer').classList.add('active')
					setTimeout(() => {
						document.getElementById(`submodal_${type}`).classList.add('active')
					}, 200);
				}, 0);
			},
            closeSubModal(type){
                document.getElementById(`submodal_${type}`).classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.submodal[type] = false
                    }, 200);
                }, 200);
            },
            resetInputs(evt) {
            	this.type = ''
            	this.optionsTypes.map((el) => {
            		if(el.for_all != this.for_all) {
            			el.$isDisabled = true
            		} else {
            			el.$isDisabled = false
            		}
            	})
            	this.selectedUsers = []
            }
		}
	};
</script>