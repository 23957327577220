<template>
    <div>
        <template v-if="announcements.length">
            <div class="item-menu" v-for="announcement in announcements" :key="announcement.id">
                <router-link :to="{name: 'announcements-show', params: { id: announcement.id } }">
                    <div class="text" :class="{new: !announcement.seen }">
                        <div class="date">{{ announcement.updated_at | moment('DD MMM YYYY') }}</div>
                        <div class="name">{{ announcement.title }}</div>
                    </div>
                    <div class="arrow"><icon-arrow /></div>
                </router-link>
            </div>
        </template>
        <div class="message-empty" v-else>{{ $t('announcements.empty_list') }}</div>
    </div>
</template>

<script>
    import IconSearch from '../../Icons/Search.vue'
    import IconArrow from '../../Icons/Arrow.vue'
    import IconPlus from '../../Icons/Plus.vue'

	export default {
		components: {
            IconSearch,
            IconArrow,
            IconPlus,
        },
        props: {
            announcements: Array
        },
	};
</script>