<template>
    <div class="modal-tbf modal-full-page modal_evaluate_promise">     
        <div class="container-modal opacity-page" v-if="loaded">
            <div class="header-modal">
                <div class="title">{{ $t('evaluate_promise.title')}}</div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>
            <div class="info-content">
                <div class="container-content-modal">
                    <div class="input-group">
                        <div class="promise-info">{{ $t('evaluate_promise.date')}} {{ moment(data.promise.created_at).format('DD MMMM, YYYY') }} </div>
                        <div class="promise-name">{{ data.promise.name }} </div>
                    </div>

                    <div class="input-group">
                        <div class="promise-info">{{ $t('evaluate_promise.contribute_to')}}</div>
                        <div class="contribute-to-kr" v-for="(krn, index) in data.promise.key_results_names.split(',')">{{ (index + 1) + '. ' +krn}}</div>
                    </div>

                    <div class="input-group" v-if="free_day == true">
                        <div class="label-header">
                            <label class="label">{{$t('responsibility.why_not')}}</label>
                            <div v-if="$v.why_not.$error" class="error-msg">{{ $t('validator.required') }}</div>
                        </div>
                        <div class="input-box" v-bind:class="{has_error: $v.why_not.$error}">
                            <textarea v-model="why_not"></textarea>
                        </div>
                    </div>
                    
                    <div class="input-group mg-0">
                        <label class="checkbox-tbf">
                            <input type="checkbox" value="1" v-model="free_day">
                            <span class="checkmark"></span>
                            <span class="text-checkbox">{{ $t('evaluate_promise.unrealised_check') }}</span>
                        </label>		
                    </div>
                    
                    <div class="form-submit">
                        <button class="btn-tbf grey center" @click="closeModal">
                            <div class="text">{{ $t('general.close') }}</div>
                        </button>
                        <button id="buttonAddPromise" class="btn-tbf blue center" @click="storePromise(true)" v-if="!free_day"> 
                            <div class="loader"></div>
                            <div class="text">{{$t('evaluate_promise.realised') }}</div>
                        </button>
                        <button id="buttonAddPromise" class="btn-tbf blue center" @click="storePromise(false, why_not)" v-else>
                            <div class="loader"></div>
                            <div class="text">{{ $t('general.update') }}</div>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <department-loader-modal v-else/>
    </div>
</template>

<script type="text/javascript">
import IconClose from '../Icons/Close'
import DepartmentLoaderModal from '../PagesLoaders/DepartmentModal.vue'

import { required, requiredIf } from 'vuelidate/lib/validators'

    export default {
        data() {
            return{
                loaded: false,
                free_day: false,
                why_not: ''
            }
        },
        validations: {
            why_not: {required: requiredIf( function() { return this.free_day == true } )}
        },
        components: {
            IconClose,
            DepartmentLoaderModal
        },
        props: {
            data: Object
        },
        mounted() {
            if(this.data.promise){
                this.loaded = true
            	setTimeout(() => {
            		$('.opacity-page').addClass('show')
            	}, 0)
            }
        },
        methods: {
			closeModal(){
				this.$emit("close_modal");
			},
            storePromise(promise_state, reason){
                var buttonName = `buttonAddPromise`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')
                this.$v.$touch()

                if(!this.$v.$invalid){
                    axios.patch(`${process.env.VUE_APP_OBJECTIVE_LINK}/api/promises/${this.data.promise.id}/evaluate`, {
                        accomplished: promise_state,
                        why_not: promise_state ? null : reason
                    }).then(() => {
                        this.closeModal()
                        this.$root.$emit('refresh_objective');
                        this.$root.$emit('refresh_timeline');
                        btnSubmitLoader.classList.add('finish')
                        setTimeout(()=>{
                            btnSubmitText.innerHTML = this.$t('btn-submit.success')
                            btnSubmit.classList.add('completed')
                            btnSubmitLoader.classList.remove('onProgress', 'finish')
                            btnSubmit.classList.remove('loading')
                            setTimeout(()=>{
                                this.$emit("close_modal");
                            }, 500)
                        }, 300)
                    }).catch(error => {
                        btnSubmitLoader.classList.add('finish')
                        setTimeout(()=>{
                            btnSubmitText.innerHTML = this.$t('btn-submit.error')
                            btnSubmit.classList.add('error')
                            btnSubmitLoader.classList.remove('onProgress', 'finish')
                            btnSubmit.classList.remove('loading')
                            setTimeout(()=>{
                                btnSubmit.classList.remove('error')
                                btnSubmitText.innerHTML = this.$t('general.add')
                                btnSubmit.disabled = false
                            }, 1000)
                        }, 300)
                    })
                }else{
                    btnSubmitLoader.classList.add('finish')
                    setTimeout(()=>{
                        btnSubmitText.innerHTML = this.$t('btn-submit.error')
                        btnSubmit.classList.add('error')
                        btnSubmitLoader.classList.remove('onProgress', 'finish')
                        btnSubmit.classList.remove('loading')
                        setTimeout(()=>{
                            btnSubmit.classList.remove('error')
                            btnSubmitText.innerHTML = this.$t('general.update')
                            btnSubmit.disabled = false
                        }, 1000)
                    }, 300)
                }
            }
        }
        
    }

</script>
