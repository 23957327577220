<template>
    <div class="modal-tbf modal-full-page meeting-summary">
        <div class="container-modal" v-if="loaded">
            <div class="header-modal">
                <div class="title">
                    {{ meeting.name }}
                </div>
                <div class="actions">
                    <button class="btn-tbf white only-icon close-btn" @click="closeModal"><icon-close class="icon-close" /></button>
                </div>
            </div>

            <div class="data-attributes">
                <div class="data">
                    <div class="label">{{ $t('meeting_session.start_data') }}</div>
                    <div class="data-info">
                        <div class="text">{{ meeting.created_at | moment('DD MMM YYYY hh:mm') }}</div>
                    </div>
                </div>
                <div class="data" v-if="meeting.duration">
                    <div class="label">{{ $t('meeting_session.duration') }}</div>
                    <div class="data-info">
                        <div class="text">
                            {{ `${String(durationHours).padStart(2, '0')}:${String(durationMinutes).padStart(2, '0')}` }}</div>
                    </div>
                </div>
                <div class="data" v-if="meeting.location">
                    <div class="label">{{ $t('add_meeting.physical_location') }}</div>
                    <div class="data-info">
                        <div class="text">{{ meeting.location }}</div>
                    </div>
                </div>
                <div class="data" v-if="meeting.link">
                    <div class="label">{{ $t('add_meeting.online_location') }}</div>
                    <div class="data-info">
                        <div class="text">{{ meeting.link }}</div>
                    </div>
                </div>
                <div class="data" v-if="meeting.rating">
                    <div class="label">{{ $t('meeting_session.review_average') }}</div>
                    <div class="data-info">
                        <div class="text"><icon-stars class="orange" />{{ meeting.rating }} <button class="action-link" @click="openSubModal('meeting_reviews')" v-if="meeting.rights.view_reviews">({{ $t('meeting_session.see_reviews') }})</button></div>
                    </div>
                </div>
            </div>

            <div class="short-description-modal" v-html="meeting.description" v-if="meeting.description"></div>

            <div class="info-content">
                <div class="container-content-modal meeting-form">
                    <template v-if="topicList.length">
                        <div class="title-section">{{ $t('meeting_session.subjects_topic') }}</div>
                        <div class="table-list">
                            <div class="row-item" v-for="topic in topicList">
                                <div class="column-icon">
                                    <icon-project v-if="topic.type === 'project'" />
                                    <icon-objective v-else-if="topic.type === 'objective'" />
                                    <icon-key-result v-else-if="topic.type === 'key_result'" />
                                    <icon-task v-else-if="topic.type === 'task'" />
                                    <icon-custom v-else-if="topic.type === 'custom'" />
                                </div>
                                <div class="column-name">
                                    {{ topic.name }}
                                </div>
                                <div class="column-type">
                                    {{ $t(`meeting_session.single_topic.${topic.type}`) }}
                                </div>
                            </div>
                        </div>
                    </template>
                    
                    <template v-if="participants.length">
                        <div class="title-section">{{ $t('add_meeting.participants') }}</div>
                        <div class="participants-list">
                            <div class="participant" v-for="user in participants">
                                <div class="avatar">
                                    <img :src="user.avatar" v-if="user.avatar">
                                    <div class="icon-user" v-else>
                                        <icon-user />
                                    </div>
                                </div>
                                <div class="data">
                                    <div class="name">{{ user.name }}</div>
                                    <div class="email">{{ user.email }}</div>
                                </div>
                                <div class="response" :class="[user.response]">
                                    <template v-if="user.response == 'yes'">{{ $t('meeting_session.presence_status.yes')}}</template>
                                    <template v-else-if="user.response == 'no'">{{ $t('meeting_session.presence_status.no')}}</template>
                                    <template v-else-if="user.response == 'holiday'">{{ $t('meeting_session.presence_status.holiday')}}</template>
                                    <template v-else-if="user.response == 'maybe'">{{ $t('meeting_session.presence_status.maybe')}}</template>
                                    <template v-else>{{ $t('meeting_session.presence_status.unconfirmed')}}</template>
                                </div>
                            </div>
                        </div>
                    </template>

                    <template v-if="!!Object.keys(summary).length">
                        <div class="title-section">{{ $t('meeting_session.summary') }}</div>
                        <div class="summary-data">
                            <div class="steps" v-for="step in summary">
                                <div class="title" v-html="step.name"></div>

                                <ul v-if="step.summaries && step.summaries.length">
                                    <li class="item-list" v-for="summaryItem in step.summaries" v-html="summaryItem"></li>
                                </ul>

                                <template v-if="step.type == 'key_result'">
                                    <div class="sub-list" v-for="keyResult in step.key_results">
                                        <div class="name" v-html="`${keyResult.name}`"></div>
                                        <ul>
                                            <li class="item-list" v-for="summaryItem in keyResult.summaries" v-html="summaryItem"></li>
                                        </ul>
                                    </div>
                                </template>

                                <template v-if="step.type == 'today_planning'">
                                    <div class="sub-list" v-for="user in step.users">
                                        <div class="name" v-html="`${user.name}`"></div>
                                        <ul>
                                            <li class="item-list" v-for="summaryItem in user.summaries" v-html="summaryItem"></li>
                                        </ul>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
    	</div>
        <DefaultModalLoader v-else/>

        <div id="submodalContainer" class="submodal-container" v-if="subModalShow">
            <div class="overlay-submodal"></div>
            <modal-meeting-reviews id="submodal_meeting_reviews" v-if="submodal.meeting_reviews" :meetingId="data.meetingEditId" @close_modal="closeSubModal('meeting_reviews')" />
        </div>
    </div>
</template>

<script type="text/javascript">
	import IconClose from '../Icons/Close'
	import IconStars from '../Icons/Stars'
    import IconProject from '@/components/Icons/Projects'
    import IconObjective from '@/components/Icons/Objectives'
    import IconTask from '@/components/Icons/Task'
    import IconCustom from '@/components/Icons/Edit'
    import IconCheckList from '@/components/Icons/CheckList'
    import IconKeyResult from '@/components/Icons/KeyResult'
    import IconUser from '@/components/Icons/User'

	import DefaultModalLoader from '../PagesLoaders/DefaultModal.vue'

    import ModalMeetingReviews from '@/components/Modals/MeetingReviews'

	export default {
        components: {
            IconClose,
            IconStars,
            IconProject,
            IconObjective,
            IconTask,
            IconCustom,
            IconCheckList,
            IconKeyResult,
            IconUser,
            DefaultModalLoader,
            ModalMeetingReviews
        },
        props: {
            data: Object
        },
		data() {
			return {
                loaded: false,
                summary: {},
                topicList: [],
                participants: [],
                meeting: {},
                durationHours: '',
                durationMinutes: '',
                subModalShow: false,
                activeSubmodal: '',
                submodal: {
                    meeting_reviews: false
                },
			};
		},
		async mounted() { 
            this.loaded = false;
            this.getMeetingSummary();
        },
		methods: {
            getMeetingSummary() {
                axios.get(`/meetings/${this.data.meetingEditId}/summary`)
                .then(({data}) => {
                    const { summary, participants, topic, name, created_at, description, duration, link, location, rating, rights } = data.data;

                    this.summary = summary;
                    this.participants = participants;
                    this.topicList = topic;
                    this.meeting = {
                        name,
                        created_at,
                        description,
                        duration,
                        link,
                        location,
                        rating,
                        rights
                    };

                    if(this.meeting.duration) {
                        this.durationHours = Math.floor(this.meeting.duration / 60);
                        this.durationMinutes = this.meeting.duration % 60;
                    }
                })
                .finally(() => {
                    this.loaded = true;
                })
            },
            closeModal(){
				this.$emit("close_modal");
			},
            openSubModal(type) {
                if(this.activeSubmodal != '' && this.activeSubmodal != type) {
                    document.getElementById(`submodal_${this.activeSubmodal}`).classList.remove('active')
                    this.submodal[this.activeSubmodal] = false;
                }

                this.subModalShow = true
                this.submodal[type] = true
                this.activeSubmodal = type;
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.add('active')
                    setTimeout(() => {
                        document.getElementById(`submodal_${type}`).classList.add('active')
                    }, 200);
                }, 0);
            },
            closeSubModal(type){
                document.getElementById(`submodal_${type}`).classList.remove('active')
                setTimeout(() => {
                    document.getElementById('submodalContainer').classList.remove('active')
                    setTimeout(() => {
                        this.subModalShow = false;
                        this.submodal[type] = false
                        this.activeSubmodal = '';
                    }, 200);
                }, 200);
            },
        }
	};
</script>