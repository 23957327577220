<template>
	<div class="modal_info modal_webinar">
		<div class="header-submodal">
			<div class="title">{{ $t('webinar_tbf.title') }}</div>
		</div>
		<div class="description-modal">
			<p>{{ $t('webinar_tbf.paragraph_1') }}</p>
			<p>{{ $t('webinar_tbf.paragraph_2') }}</p>
			<p>{{ $t('webinar_tbf.paragraph_3') }}</p>
			
			<div class="webinar-link">
				<input class="link" id="divToCopy" v-if="loaded" :value="linkWebinar" readonly/>
				<div class="placeholder-loader" v-else style="height: 40px; width: 100%; border-radius: 8px; margin-top: 10px;"></div>
			</div>

			<div class="add-to-calendar">
				<a target="_blank" href="https://calendar.google.com/event?action=TEMPLATE&amp;tmeid=NTE5Z2RhcnJpdTZ0dGF1Z2c3ZjkzbzJmdHVfMjAyMjAxMjdUMDgwMDAwWiBjX3M3Y2c2YXY0aWs1ZjRudnBqZXNkZWpuYjk4QGc&amp;tmsrc=c_s7cg6av4ik5f4nvpjesdejnb98%40group.calendar.google.com&amp;scp=ALL"><icon-date /> {{ $t('webinar_tbf.add_to_google_calendar') }}</a>
			</div>

			<div class="add-to-calendar">
				<a target="_blank" href="https://help.tbf.ro/ro/collections/3175545-cum-sa-folose%C8%99ti-softurile-din-tbf-digital"><icon-youtube /> {{ $t('webinar_tbf.view_all_webinars') }}</a>
			</div>
		</div>

		<div class="button-actions">
			<button class="btn-tbf grey center" @click="$emit('close_modal')">
				<div class="text">{{ $t('general.close') }}</div>
			</button>
			<button id="copyWebinarBtn" class="btn-tbf blue center" @click="loaded ? copyWebinar() : ''">
				<div class="loader"></div>
				<div class="text">{{ $t('reset_password_modal.copy_link') }}</div>
			</button>
		</div>
	</div>
</template>

<script type="text/javascript">
	import IconDate from '../Icons/Date'
	import IconYoutube from '../Icons/Youtube'

	export default {
		data() {
			return {
				loaded: true,
				linkWebinar: 'https://us02web.zoom.us/j/81124987577',
				email: 'ceva'
			};
		},
		components: {
			IconDate,
			IconYoutube
		},
		methods: {
			copyWebinar(){
				var buttonName = `copyWebinarBtn`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)

				var copyText = document.getElementById("divToCopy");
				copyText.select();
				copyText.setSelectionRange(0, 99999);
				document.execCommand('copy');

				btnSubmit.classList.add('completed')
				btnSubmitText.innerHTML = this.$t('reset_password_modal.copied')

				setTimeout(()=>{
					btnSubmit.classList.remove('completed')
					btnSubmitText.innerHTML = this.$t('reset_password_modal.copy_link')
				}, 2000)
			}
		}
	};
</script>

<style lang="scss">
	.modal_info.modal_webinar{
		.description-modal{
			max-width: 100% !important;
			text-align: left;
			.webinar-link{
				margin-top: 15px;
				.link{
					width: 100%;
					font-size: 14px;
					margin-top: 10px;
					border-radius: 8px;
					border: 1px solid $borderColor;
					height: 40px;
					padding: 3px 15px;
					color: $primary;
					&:focus{
						box-shadow: none;
						outline: none;
					}
				}
			}
			.add-to-calendar{
				margin-top: 15px;
				a{
					color: $blue;
					font-size: 14px;
					cursor: pointer;
					display: flex;
					align-items: center;
					width: fit-content;
					// padding: 4px 15px;
					// height: initial;
					&:hover{
						text-decoration: underline;
					}
					svg{
						height: 15px;
						width: auto;
						margin-right: 10px;
					}
				}
			}
		}
	}
</style>