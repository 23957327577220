<template>
	<div class="modal_info modal_evaluate_responsibility">
		<template>
			<div class="header-submodal">
				<div class="title">{{ data.title }}</div>
			</div>
			<div class="content">
				<template v-if="data.log_type == 'performance_reminder'">
					<div class="user">
						<img class="user-avatar" :src="data.user.avatar" />
						<div class="user-name">
							{{ data.user.name }}
						</div>
					</div>
					<div class="frequency">{{ $t("general.frequency_type") }} {{ $t(`profile.recurrence_evaluations_list.${data.frequency}`) }}</div>
				</template>
				<template v-else>
					<div class="items">
						<div class="item">
							<div class="item-title">{{ $t("general.objective") }}</div>
							<div class="item-name">{{ data.objective.name }}</div>
						</div>
						<div class="item">
							<div class="item-title">{{ $t("general.key_result") }}</div>
							<div class="item-name">{{ data.key_result.name }}</div>
						</div>
						<div class="item">
							<div class="item-title">{{ $t("general.frequency_type") }}</div>
							<div class="item-name">{{ $t("general.days", { n: data.key_result.measurement }) }}</div>
						</div>
					</div>
				</template>
			</div>
			<div class="button-actions">
				<button class="btn-tbf center grey" @click="closeModal">
					<div class="text">{{ $t("general.close") }}</div>
				</button>
				<template v-if="data.log_type == 'performance_reminder'">
					<button class="btn-tbf center blue" @click="goToEvaluations()">
						<div class="text">{{ $t("general.evaluate") }}</div>
					</button>
				</template>
				<template v-else>
					<a class="btn-tbf center blue" :href="`${objectives_fe_link}/obiectiv/${data.objective.slug}/${data.key_result.id}/actualizari`">
						<div class="text">{{ $t("general.update") }}</div>
					</a>
				</template>
			</div>
		</template>
	</div>
</template>

<script>
export default {
	data() {
		return {
			objectives_fe_link: process.env.VUE_APP_OBJECTIVE_FE_LINK,
		};
	},
	props: {
		data: Object,
	},
	mounted() {},
	methods: {
		closeModal() {
			this.$emit("close_modal");
		},
		goToEvaluations() {
			this.$router.push({ name: "user-show", params: { slug: this.data.user.slug }, query: { tab: "assessments" } });
			this.closeModal();
		},
	},
};
</script>
